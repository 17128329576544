@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.join {
  padding: 90px 0 180px;
  &__inner {
    &-title {
      text-align: center;
      max-width: 855px;
      margin: 0 auto 50px;
      h2 {
        margin-bottom: 10px;
        color: $white;
      }
    }
    &-foot {
      display: flex;
      justify-content: center;
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .join {
    padding: 70px 0 140px;
  }
}
// 1180px
@include xlg-xxlg {
  .join {
    padding: 50px 0 100px;
    &__inner {
      &-title {
        margin-bottom: 35px;
      }
    }
  }
}
// 840px
@include md-lg-extra {
  .join {
    padding: 30px 0 60px;
  }
}
// 540px
@include xsm-sm {
  .join {
    &__inner {
      &-title {
        margin-bottom: 24px;
      }
    }
  }
}
