@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.summary {
  &__head {
    margin-bottom: 30px;
    h4 {
      color: $white;
      margin-bottom: 10px;
    }
  }
  &__inner {
    background: #0e0e0e;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 25px;
  }
  margin-bottom: 50px;
}
.overview {
  box-sizing: border-box;
  width: 100%;
  &__select {
    width: 140px;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin-bottom: 35px;
    margin-bottom: 0px;
    h4 {
      color: $white;
    }
  }
  &__body {
    display: flex;
    margin: 0 -10px 40px -10px;
  }
  &Item {
    display: flex;
    align-items: center;
    padding: 16px 8px 16px 20px;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    margin: 0 10px;
    cursor: pointer;
    @include transition;
    width: calc(25% - 20px);
    &:hover {
      border: 1px solid $primary;
      background: #090909;
    }
    &__icon {
      width: 45px;
      border-radius: 50%;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      svg {
        width: 30px;
        height: 30px;
        color: $black;
      }
    }
    &__text {
      width: calc(100% - 60px);
      color: $white;
      p {
        margin-bottom: 4px;
      }
    }
  }
}
.chart {
  width: 100%;
  padding: 12px;
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  position: relative;
  &__select {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 140px;
  }
  &__labels {
    position: absolute;
    top: 12px;
    left: 40px;
    display: flex;
    align-items: center;
  }
  &__label {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 20px;
    line-height: 18px;
    span {
      width: 12px;
      height: 12px;
      border-radius: 2px;
      display: inline-block;
      margin-right: 5px;
    }
  }
  &__inner {
    width: 100%;
    height: 400px;
    padding-top: 50px;
    box-sizing: border-box;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
}
// 1540px
@include xxxxlg-xxxxxlg {
  .overview {
    &__body {
      margin: 0 -6px 40px -6px;
    }
    &Item {
      margin: 0 6px;
      width: calc(25% - 12px);
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .summary {
    &__inner {
      padding: 16px;
    }
  }
}
// 1080px
@include lg-xlg-extra {
  .overview {
    &__body {
      flex-wrap: wrap;
      margin: 0 -10px 20px -10px;
    }
    &Item {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }
}
// 930px
@include md-lg {
  .summary {
    &__inner {
      flex-direction: column;
    }
  }
  .overview {
    width: 100%;
    margin-bottom: 20px;
  }
  .chart {
    width: 100%;
    padding-top: 50px;
    &__inner {
      height: 400px;
    }
    &__select {
      top: 40px;
    }
    &__labels {
      left: 13px;
    }
  }
}
// 650px
@include sm-md-extra {
  .summary {
    &__inner {
      padding: 16px;
    }
  }
  .overview {
    &__select {
      margin-left: auto;
    }
    &__head {
      flex-direction: column;
      h4 {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    &__body {
      flex-direction: column;
      margin: 0 0 20px 0;
    }
    &Item {
      margin: 0 0 10px 0;
      width: 100%;
      &:last-child {
        margin: 0;
      }
    }
  }
}
