@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.feature {
  padding: 90px 0;
  &__head {
    color: $white;
    margin-bottom: 50px;
    h2 {
      margin-bottom: 15px;
      max-width: 440px;
    }
  }
  &__inner {
    position: relative;
    padding-right: calc(50% - 15px);
    box-sizing: border-box;
  }
  &__list {
    margin-bottom: 50px;
  }

  &Item {
    max-width: 650px;
    width: calc(100% - 15px);
    margin-bottom: 20px;
    &__image {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(50% - 15px);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      max-width: 560px;
      background: #0e0e0e;
      border-radius: 20px;
      padding: 36px 30px;
      img {
        width: 100%;
      }
    }
    &__content {
      box-sizing: border-box;
      padding: 20px 25px;
      background: $dark-bg;
      border-radius: 10px;
      cursor: pointer;
      @include transition;
      &.active {
        background: $primary;
        h4 {
          color: $black;
        }
        p {
          color: $black;
        }
      }
      p {
        @include transition;
      }
      h4 {
        color: $white;
        @include transition;
        margin-bottom: 15px;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    h4 {
      margin-bottom: 15px;
      color: $white;
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .feature {
    padding: 70px 0;
  }
}
// 1180px
@include xlg-xxlg {
  .feature {
    padding: 50px 0;
  }
}
// 1024px
@include lg-xlg {
  .feature {
    &__head {
      margin-bottom: 35px;
    }
    &__list {
      margin-bottom: 35px;
    }
    &__inner {
      padding: 0;
    }
    &Item {
      margin-bottom: 20px;
      padding: 20px;
      max-width: unset;
      width: 100%;

      &__image {
        position: relative;
        width: 100%;
        max-width: unset;
        padding: 30px;
      }
    }
  }
}
// 840px
@include md-lg-extra {
  .feature {
    padding: 30px 0;
    &__list {
      width: 100%;
    }
    &__head {
      width: 100%;
    }
    &__content {
      width: 100%;
      max-width: unset;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__image {
      position: static;
      transform: translate(0);
      width: 100%;
      margin: 0 auto 30px;
    }
  }
}
// 540px
@include xsm-sm {
  .feature {
    &__head {
      margin-bottom: 24px;
    }

    &Item {
      padding: 0;
      &__content {
        margin-bottom: 30px;
      }
      &__image {
        border-radius: 0;
        border: unset;
        margin: 0 -13px;
        width: calc(100% + 26px);
        background: rgba(38, 206, 173, 0.08);
        padding: 30px 13px;
        img {
          width: 100%;
          max-width: unset;
        }
      }
    }
  }
}
