@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.tooltip {
  position: relative;
  cursor: pointer;
  &:hover {
    .tooltip {
      &__icon {
        color: $white;
      }
      &__text {
        padding-bottom: 10px;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &.left {
    .tooltip {
      &__text {
        left: unset;
        right: 0;
      }
    }
  }
  &__icon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888;
    svg {
      width: 100%;
      height: 100%;
      @include transition;
    }
  }
  &__text {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 180px;
    font-size: 12px;
    line-height: 16px;
    visibility: hidden;
    @include transition;
    padding-bottom: 0;
    &-inner {
      background: #333;
      padding: 10px;
      border-radius: 10px;
    }
  }
}
