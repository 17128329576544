@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.input {
  &__outer {
    margin-bottom: 15px;
    label {
      font-family: $mainFont;
      display: block;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $white;
      margin-bottom: 12px;
    }
  }
  position: relative;
  input {
    width: 100%;
    background: #181818;
    border: 1px solid #373737;
    box-sizing: border-box;
    padding: 16px;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 14px;
    border-radius: 10px;
    line-height: 17px;
    color: $white;
    @include transition;
    &:focus,&:hover {
      border: 1px solid $primary;
    }
    &::placeholder {
      color: #b7b7b7;
    }
  }
}
