@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.faq {
  padding: 90px 0;
  &__inner {
    &-title {
      text-align: center;
      max-width: 855px;
      margin: 0 auto 50px;
      h2 {
        margin-bottom: 10px;
        color: $white;
      }
    }
    &-col {
      max-width: 980px;
      margin: 0 auto;
    }
  }
  &Item {
    margin-bottom: 15px;
    background: #0e0e0e;
    border-radius: 10px;
    &:last-child {
      margin: 0;
    }
    &__head {
      padding: 20px 60px 20px 20px;
      position: relative;
      cursor: pointer;
      transition: 0.3s ease;
      &.active {
        padding-bottom: 15px;
        span {
          &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
            opacity: 0;
          }
        }
      }
      span {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        display: flex;
        border: 1px solid $text-gray;
        border-radius: 50%;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 8px;
          height: 2px;
          border-radius: 2px;
          content: "";
          transform: translate(-50%, -50%);
          background-color: $text-gray;
        }
        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 2px;
          border-radius: 2px;
          height: 8px;
          content: "";
          transform: translate(-50%, -50%);
          background-color: $text-gray;
          transition: 0.3s ease;
        }
      }
      h5 {
        color: $white;
      }
    }
    &__body {
      padding: 0 60px 0 20px;
      opacity: 0;
      overflow: hidden;
      max-height: 0;
      transition: 0.3s ease;
      &.active {
        opacity: 1;
        max-height: 400px;
        padding: 0 60px 20px 20px;
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .faq {
    padding: 70px 0;
  }
}
// 1180px
@include xlg-xxlg {
  .faq {
    padding: 50px 0;
    &__inner {
      &-title {
        margin-bottom: 35px;
      }
    }
  }
}
// 840px
@include md-lg-extra {
  .faq {
    padding: 30px 0;
  }
}

// 540px
@include xsm-sm {
  .faq {
    &__inner {
      &-title {
        margin-bottom: 24px;
      }
    }
    &Item {
      &__head {
        padding: 20px 44px 20px 20px;
        span {
          width: 18px;
          height: 18px;
          right: 15px;
        }
      }
      &__body {
        padding: 0 44px 0 20px;
        &.active {
          padding: 0 44px 20px 20px;
        }
      }
    }
  }
}
