@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.settings {
  padding: 20px 0;
  &__inner {
    padding: 50px 0;
  }
  &__head {
    margin-bottom: 35px;
    h4 {
      color: $white;
      margin-bottom: 10px;
    }
  }
  &__body {
    padding: 25px;
    background: #0e0e0e;
    border: 1px solid #373737;
    border-radius: 10px;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        display: flex;
        align-items: center;
        button {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 16px;
          color: $text-gray;
          border-radius: 50%;
          background: #373737;
          cursor: pointer;
          @include transition;
          &:hover {
            color: $white;
          }
          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
      h6 {
        width: 180px;
        color: $white;
        margin-right: 10px;
      }
    }
    &-act {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      //max-width: 685px;
      &.start {
        align-items: flex-start;
        justify-content: unset;
        .settings__body-act-text {
          max-width: 828px;
          flex: 1;
          margin-right: 38px;
        }
      }
      &-text {
        max-width: 451px;
        h5 {
          color: $white;
          margin-bottom: 10px;
        }
      }
      button {
        font-weight: 400;
        padding: 13px;
        width: 205px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.container {
  max-width: 1090px;
  margin: 0 auto;
}
// 1540px
@include xxxxlg-xxxxxlg {
  .settings {
    &__inner {
      padding: 24px 0;
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .settings {
    &__inner {
      padding: 0;
    }
  }
}
// 1180px
@include xlg-xxlg {
  .settings {
    padding: 40px 0;
    &__inner {
      padding: 0;
    }
  }
}
// 840px
@include md-lg-extra {
  .settings {
    &__body {
      &-item {
        h6 {
          width: calc(50% - 10px);
        }
        p {
          width: 50%;
        }
      }
      &-act {
        flex-direction: column;
        align-items: flex-start;
        &-text {
          max-width: unset;
          width: 100%;
          margin-bottom: 25px;
        }
      }
    }
  }
}
// 540px
@include xsm-sm {
  .settings {
    &__body {
      &-item {
        flex-direction: column;
        align-items: flex-start;
        h6 {
          width: 100%;
          margin: 0 0 10px 0;
        }
        p {
          width: 100%;
        }
      }
    }
  }
}
