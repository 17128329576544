@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.info {
  display: flex;
  margin: 0 -10px 40px -10px;
  &Item {
    width: calc(30% - 20px);
    margin: 0 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 25px;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    &:nth-child(2) {
      .infoItem__icon {
        background: #61e5fc;
      }
    }
    &:nth-child(3) {
      width: calc(40% - 20px);
      .infoItem__icon {
        background: #fd7c5c;
      }
    }
    &__icon {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $black;
      background: #ff696f;
      border-radius: 50%;
      margin-right: 15px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    &__content {
      width: calc(100% - 75px);
      color: $white;
      p {
        margin-bottom: 10px;
      }
      &-number {
        font-weight: 500;
        font-size: 26px;
        line-height: 31px;
      }
      &-date {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;

        svg {
          width: 24px;
          height: 24px;
          margin: 0 10px;
        }
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .info {
    &Item {
      &__content {
        &-date {
          font-size: 16px;
          svg {
            width: 20px;
            height: 20px;
            margin: 0 5px;
          }
        }
      }
    }
  }
}
// 930px
@include md-lg {
  .info {
    flex-direction: column;
    margin: 0;
    &Item {
      width: 100% !important;
      margin: 0 0 20px 0;
    }
  }
}
