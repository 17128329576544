@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.modal {
  margin: 0 auto;
  width: 100%;
  max-width: 520px;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(24, 24, 24, 0.64);
    border: 1px solid #373737;
    backdrop-filter: blur(25px);
    border-radius: 20px;
  }
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    padding: 195px 20px;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(4px);
  }
  &__inner {
    position: relative;
    z-index: 2;
    padding: 40px 30px 30px;
  }
  &__icon {
    width: 120px;
    height: 120px;
    display: flex;
    margin: 0 auto 30px;
    svg {
      width: 100%;
      height: 100%;
      color: #26cead;
    }
  }
  &__title {
    text-align: center;
    margin-bottom: 50px;
    &.sm {
      margin-bottom: 30px;
    }
    &.mid {
      margin-bottom: 40px;
    }
    h4 {
      color: $white;
      margin-bottom: 10px;
      &:last-child {
        margin: 0;
      }
    }
    p {
      max-width: 430px;
      margin: 0 auto;
    }
  }
  &__col {
    margin-bottom: 50px;
  }
  &__foot {
    display: flex;
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
    button {
      font-family: $mainFont;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $white;
      padding: 15px 20px;
      cursor: pointer;
      transition: 0.3s ease;
      border-radius: 10px;
      width: calc(50% - 10px);
    }
  }
  &__cancel {
    background-color: #373737;
  }
  &__submit {
    background-color: #ef4047;
  }
}
.input {
  display: flex;
  position: relative;
  &__outer {
    margin-bottom: 25px;
    &:last-child {
      margin: 0;
    }
    p {
      color: $white;
      margin-bottom: 10px;
    }
  }
  input,
  select {
    width: 100%;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    color: $text-gray;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 10px 44px 10px 15px;
    -webkit-appearance: none;
    @include transition;
    &:focus,&:hover {
      border: 1px solid $primary;
    }
    &::placeholder {
      color: $text-gray;
    }
  }

  select {
    cursor: pointer;
  }
   
  button {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: flex;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s ease;
    color: #888888;
    z-index: 2;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.select {
  width: 100%;
  & > div {
    & > div {
      &:nth-child(1) {
        padding: 10px 16px;

        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.05em;
        color: #cacaca;
      }
    }
  }
}
.check {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    margin: 0;
  }
  &__title {
    width: calc(100% - 80px);
    max-width: 360px;
  }
  &__box {
    width: 60px;
    height: 34px;
    position: relative;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      cursor: pointer;
      opacity: 0;
      &:checked ~ span {
        background-color: #1f9e85;
        &::before {
          transform: translate(27px, -50%);
        }
      }
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #373737;
      border-radius: 20px;
      transition: 0.3s ease;
      &::before {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        content: "";
        background-color: $white;
        border-radius: 50%;
        transition: 0.3s ease;
      }
    }
  }
  h6 {
    margin-bottom: 10px;
    color: $white;
  }
  p {
    color: #aeaeae;
  }
}
// 1240px
@include xlg-xxlg-extra {
  .modal {
    &__wrapper {
      padding: 100px 20px;
    }
  }
}
// 840px
@include md-lg-extra {
  .modal {
    margin: auto;
    &__wrapper {
      padding: 50px 20px;
      display: flex;
      flex-direction: column;
    }
  }
}
// 540px
@include xsm-sm {
  .modal {
    &__wrapper {
      padding: 20px;
    }
    &__inner {
      padding: 30px 20px;
    }
    &__title {
      margin-bottom: 30px !important;
    }
    &__col {
      margin-bottom: 30px;
    }
  }
  .input {
    &__outer {
      margin-bottom: 20px;
    }
  }
}
