@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.audit {
  padding: 70px 0;
  max-width: 1090px;
  margin: 0 auto;
  &__title {
    margin-bottom: 40px;
    h4 {
      color: $white;
      margin-bottom: 10px;
    }
  }
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  h4 {
    color: $white;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
    @include transition;
    box-sizing: border-box;
    border: 1px solid #373737;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #cacaca;
    background: transparent;
    &:hover {
      color: $primary;
      border: 1px solid $primary;
    }
    svg {
      width: 24px;
      margin-right: 10px;
      height: 24px;
    }
  }
  .date,
  .btn {
    width: calc(25% - 10px);
  }
  .select {
    width: calc(25% - 10px);
    & > div {
      & > div {
        &:nth-child(1) {
          background: transparent;
        }
      }
    }
    &s {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 800px;
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .audit {
    padding: 50px 0;
  }
}
// 1240px
@include xlg-xxlg-extra {
  .head {
    flex-direction: column;
    align-items: flex-start;
    h4 {
      margin-bottom: 15px;
    }
    .select {
      &s {
        max-width: unset;
        width: 100%;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .head {
    .select,
    .btn,
    .date {
      width: calc(50% - 10px);
      margin: 5px;
    }
    .selects {
      flex-wrap: wrap;
      margin: 0 -5px;
    }
  }
}
// 540px
@include xsm-sm {
  .head {
    .select,
    .btn,
    .date {
      width: 100%;
      margin: 0 0 10px 0;
    }
    .selects {
      margin: 0;
    }
  }
}
