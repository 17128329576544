@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.intro {
  padding: 130px 0 100px 0;
  position: relative;
  &__shape {
    position: absolute;
    top: 0;
    right: 40px;
    width: 45%;
    max-width: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 010%;
      width: 320px;
      height: 320px;
      background: rgba(239, 99, 64, 0.3);
      filter: blur(200px);
      z-index: -1;
    }
    &::after {
      position: absolute;
      content: "";
      bottom: 15%;
      left: -10%;
      width: 320px;
      border-radius: 50%;
      height: 320px;
      background: rgba(38, 206, 173, 0.3);
      z-index: -1;
      filter: blur(200px);
    }
    img {
      width: 100%;
    }
  }
  &__inner {
    position: relative;
    z-index: 1;
    h1 {
      color: $white;
      margin-bottom: 28px;
    }
    h4 {
      margin-bottom: 60px;
      color: $white;
    }
    &-btn {
      button {
        display: flex;
        align-items: center;
      }
    }
    &-tag {
      margin-bottom: 40px;
      button {
        display: flex;
        align-items: center;
        padding: 13px 36px;
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .intro {
    padding: 90px 0 70px 0;
    &__shape {
      max-width: 450px;
    }
    &__inner {
      &-tag {
        button {
          padding: 13px 24px;
        }
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .intro {
    &__shape {
      right: -100px;
    }
  }
}
// 540px
@include xsm-sm {
  .intro {
    &__shape {
      display: none;
    }
    &__inner {
      h1 {
        margin-bottom: 20px;
      }
      h4 {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        margin-bottom: 50px;
      }
      &-tag {
        button {
          font-weight: 400;
          font-size: 14px;
          padding: 6px 9px;
          border-radius: 10px;
        }
      }
    }
  }
}
