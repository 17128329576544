@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.price {
  padding: 90px 0;
  &__head {
    text-align: center;
    margin-bottom: 54px;
    h2 {
      color: $white;
      margin-bottom: 10px;
    }
  }
  &__inner {
    display: flex;
    margin: 0 -10px;
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 280px;
    margin: 0 auto;
    box-sizing: border-box;
    border: 1px solid #181818;
    border-radius: 30px;
    padding: 10px;
    margin-bottom: 50px;
    &-btn {
      display: flex;
      align-items: center;
      padding: 8px 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #cacaca;
      @include transition;
      border-radius: 50px;
      cursor: pointer;
      span {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        display: block;
        @include transition;
        padding: 2.5px 10px;
        border-radius: 50px;
        background: #373737;
        margin-left: 10px;
      }
      &.active {
        background: $primary;
        color: #181818;
        span {
          background: #00000066;
          color: $white;
        }
      }
    }
  }
  &Item {
    width: calc(25% - 20px);
    margin: 0 10px;
    box-sizing: border-box;
    padding: 30px 25px;
    background: $dark-bg;
    border-radius: 10px;
    h6 {
      margin-bottom: 20px;
    }
    &__row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      justify-content: space-between;
    }
    &__price {
      display: flex;
      align-items: flex-end;
      color: $white;
    }
    &__save {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #000000;
      border-radius: 50px;
      background: $primary;
      padding: 2px 10px;
    }
    &__addon {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }
    &__btn {
      margin-bottom: 25px;
      width: 100%;
      button {
        width: 100%;
      }
    }
    ul {
      li {
        display: flex;
        align-items: center;
        color: $white;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        svg {
          width: 24px;
          height: 24px;
          color: $primary;
          margin-right: 15px;
        }
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .price {
    padding: 70px 0;
  }
}
// 1380px
@include xxlg-xxxlg {
  .price {
    &Item {
      &__price {
        h3 {
          font-size: 22px;
          line-height: 22px;
        }
      }
      &__save {
        font-size: 12px;
        line-height: 14px;
        padding: 2px 6px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .price {
    padding: 85px 0;
    &__inner {
      flex-wrap: wrap;
    }
    &Item {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }
}
// 650px
@include sm-md-extra {
  .price {
    &__head {
      margin-bottom: 20px;
    }
    &Item {
      width: calc(100% - 20px);
    }
  }
}
