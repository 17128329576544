@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.layout {
  padding-left: 317px;
  &.hiddenSide {
    padding-left: 0;
  }
  &__inner {
    padding-left: 40px;
  }
}
.container {
  max-width: 1370px;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
}
// 1540px
@include xxxxlg-xxxxxlg {
  .layout {
    padding-left: 280px;
    &__inner {
      padding-left: 0;
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .layout {
    padding-left: 240px;
  }
  .container {
    padding: 0 20px;
  }
}
// 1180px
@include xlg-xxlg {
  .layout {
    padding: 0;
  }
}
// 650px
@include sm-md-extra {
  .container {
    padding: 0 13px;
  }
}
