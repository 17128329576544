@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.feature {
  padding: 90px 0;
  &__head {
    text-align: center;
    color: $white;
    margin-bottom: 70px;
    h2 {
      margin-bottom: 10px;
    }
  }
  &__inner {
    position: relative;
    padding-right: 52%;
  }
  &Item {
    max-width: 550px;
    width: calc(100% - 15px);
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    &__image {
      position: absolute;
      top: 0;
      right: 0;
      width: 52%;
      height: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      background: #0e0e0e;
      border: 1px solid #373737;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 60px;
      &-inner {
        background: rgba($color: $primary, $alpha: 0.08);
        width: 100%;
        height: 100%;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 560px;
        box-sizing: border-box;
        img {
          width: 100%;
          max-width: 470px;
        }
      }
    }
    &__content {
      box-sizing: border-box;
      padding: 20px 25px;
      background: $dark-bg;
      border-radius: 10px;
      cursor: pointer;
      @include transition;
      &.active {
        background: $primary;
        h4 {
          color: $black;
        }
        p {
          color: $black;
        }
      }
      p {
        @include transition;
      }
      h4 {
        color: $white;
        @include transition;
        margin-bottom: 15px;
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .feature {
    padding: 70px 0;
    &Item {
      max-width: 500px;
      &__image {
        padding: 40px;
        &-inner {
          img {
            max-width: 400px;
          }
        }
      }
    }
  }
}
// 1240px
@include lg-xlg {
  .feature {
    &__head {
      margin-bottom: 50px;
    }
    &Item {
      &__image {
        padding: 30px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .feature {
    &__inner {
      padding: 0;
    }
    &Item {
      max-width: unset;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &__content {
        margin-bottom: 30px;
      }
      &__image {
        position: relative;
        width: 100%;
        padding: 30px;
      }
    }
  }
}
@include xsm-sm {
  .feature {
    padding: 85px 0;
    &__head {
      text-align: left;
    }
    &Item {
      &__image {
        border-radius: 0;
        border: unset;
        margin: 0 -13px;
        width: calc(100% + 26px);
        background: rgba($color: $primary, $alpha: 0.08);
        padding: 30px 13px;
        &-inner {
          background: transparent;
          img {
            width: 100%;
            max-width: unset;
          }
        }
      }
    }
  }
}
