@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.table {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    margin-bottom: 16px;
    button {
      padding: 11.5px 20px;
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
    }
    .date {
      width: 185px;
    }
  }
  &__outer {
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  &__inner {
    /* width */
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #373737;
      border-radius: 0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: #888888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #888888;
    }
  }
  &__status {
    border-radius: 10px;
    display: inline-block;
    margin-right: 15px;
    background: #888888;
    padding: 4px 8px;
    &.Confirmed {
      background: rgba(38, 206, 173, 0.08);
      color: #26cead;
    }
    &.Declined {
      background: rgba(255, 82, 82, 0.08);
      color: #ff5252;
    }
    &.Processing {
      background: rgba(255, 207, 82, 0.08);
      color: #ffcf52;
    }
    &.Expired {
      background: rgba(255, 82, 82, 0.08);
      color: #ff5252;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      border-bottom: 1px solid #373737;
    }
    tr {
      cursor: pointer;
      &:hover {
        td {
          background: #373737;
        }
      }
    }
    th,
    td {
      padding: 15px 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $white;
      text-align: left;
      white-space: nowrap;
      @include transition;
      &:last-child {
        width: 64px;
      }
      &:nth-child(3) {
        width: auto;
      }
    }
  }
  &__action {
    button {
      width: 24px;
      height: 24px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #888888;
      cursor: pointer;
      &:hover {
        color: $white;
      }
      svg {
        width: 100%;
        height: 100%;
        @include transition;
      }
    }
  }
  &__pagination {
    padding: 25px 20px 30px 25px;
    position: relative;
    border-top: 1px solid #373737;
    margin-top: 10px;
    &-btns {
      display: flex;
      align-content: center;
      justify-content: center;
      button {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        color: $text-gray;
        margin: 0 8px;
        cursor: pointer;
        &.active {
          color: $primary;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    p {
      position: absolute;
      left: 20px;
      color: $text-gray;
    }
  }
}
// 1024px
@include lg-xlg {
  .table {
    &__pagination {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        order: 2;
        position: relative;
        left: 0;
        margin-top: 20px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
// 540px
@include xsm-sm {
  .table {
    &__head {
      flex-direction: column;
      align-items: flex-start;
      h4 {
        margin-bottom: 16px;
      }
      button {
        width: 100%;
      }
      .date {
        width: 100%;
      }
    }
    &__pagination {
      padding: 20px 13px;
    }
  }
}
