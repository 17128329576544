@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.support {
  padding: 20px 0;
  &__inner {
    padding: 50px 0;
  }
  &__head {
    margin-bottom: 35px;
    h4 {
      color: $white;
      margin-bottom: 10px;
    }
  }
  &__body {
    padding: 30px;
    background: #0e0e0e;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      display: flex;
      align-items: center;
      padding: 13px 18px;
      font-weight: 400;
    }
    &-inner {
      display: flex;
      width: calc(100% - 250px);
      align-items: center;
    }
    &-content {
      max-width: 530px;
      width: calc(100% - 154px);
      h4 {
        color: $white;
        margin-bottom: 10px;
      }
    }
    &-icon {
      width: 124px;
      height: 106px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      border-radius: 10px;
      background: #181818;
      margin-right: 30px;
      svg {
        width: 70px;
        height: 70px;
      }
    }
  }
}
.container {
  max-width: 1090px;
  margin: 0 auto;
}
// 1540px
@include xxxxlg-xxxxxlg {
  .support {
    &__inner {
      padding: 24px 0;
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .support {
    &__inner {
      padding:  0;
    }
  }
}
// 1180px
@include xlg-xxlg {
  .support {
    padding: 40px 0;
    &__inner {
      padding: 0;
    }
  }
}
// 1024px
@include lg-xlg {
  .support {
    &__body {
      flex-direction: column;
      align-items: flex-start;
      &-inner {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}
// 650px
@include sm-md-extra {
  .support {
    &__body {
      padding: 30px 20px;
      &-icon {
        margin: 0 0 20px 0;
      }
      &-content {
        width: 100%;
      }
      &-inner {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
// 540px
@include xsm-sm {
  .support {
    &__body {
      button {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
