@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.modal {
  background: rgba($color: #000000, $alpha: 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  z-index: 101;
  &__inner {
    margin: auto;
    padding: 40px;
    box-sizing: border-box;
    background: rgba(24, 24, 24, 0.64);
    border: 1px solid #373737;
    backdrop-filter: blur(25px);
    border-radius: 20px;
    width: 100%;
    max-width: 520px;
  }
  &.wide {
    .modal__inner {
      max-width: 734px;
    }
  }
}
// 650px
@include sm-md-extra {
  .modal {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    &__inner {
      margin: auto 0 0 0;
      max-width: unset;
      box-sizing: border-box;
      overflow-y: auto;
      min-height: 85%;
      padding: 40px 13px 40px 13px;
      &-logo {
        width: 140px;
        margin-bottom: 30px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }
}
