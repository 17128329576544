@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.table {
  &__outer {
    h4 {
      margin-bottom: 25px;
      color: $white;
    }
  }
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  &__inner {
    /* width */
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #373737;
      border-radius: 0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: #888888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #888888;
    }
  }
  &__status {
    display: flex;
    align-items: center;
    span {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
      margin-right: 15px;
      background: #888888;
      &.confirmed {
        background: $primary;
      }
      &.declined {
        background: $secondary;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;

    th {
      border-bottom: 1px solid #373737;
    }
    tr {
      cursor: pointer;
      &:hover {
        td {
          background: #373737;
        }
      }
    }
    th,
    td {
      padding: 15px 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $white;
      text-align: left;
      white-space: nowrap;
      @include transition;
      &:last-child {
        width: 64px;
      }
      button {
        width: 24px;
        height: 24px;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #888888;
        cursor: pointer;
        &:hover {
          color: $white;
        }
        svg {
          width: 100%;
          height: 100%;
          @include transition;
        }
      }
    }
  }
  &__pagination {
    padding: 25px 20px 30px 25px;
    position: relative;
    border-top: 1px solid #373737;
    margin-top: 10px;
    &-btns {
      display: flex;
      align-content: center;
      justify-content: center;
      button {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        color: $text-gray;
        margin: 0 8px;
        cursor: pointer;
        &.active {
          color: $primary;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    p {
      position: absolute;
      left: 20px;
      color: $text-gray;
    }
  }
}
// 1024px
@include lg-xlg {
  .table {
    &__pagination {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        order: 2;
        position: relative;
        left: 0;
        margin-top: 20px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
// 540px
@include xsm-sm {
  .table {
    &__pagination {
      padding: 20px 13px;
    }
  }
}
