@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.modal {
  &__title {
    margin-bottom: 50px;
    text-align: center;
    &.sm {
      margin-bottom: 30px;
    }
    h4 {
      color: $white;
      margin-bottom: 10px;
    }
  }
  &__body {
    margin-bottom: 40px;
  }
  &__foot {
    display: flex;
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
    button {
      font-family: $mainFont;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $white;
      padding: 15px 20px;
      cursor: pointer;
      transition: 0.3s ease;
      border-radius: 10px;
      width: calc(50% - 10px);
    }
  }
  &__icon {
    width: 120px;
    height: 120px;
    display: flex;
    margin: 0 auto 50px;
    svg {
      width: 100%;
      height: 100%;
      color: #26cead;
    }
  }
}
.input {
  position: relative;
  input {
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 16px 50px 16px 16px;
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    color: $white;
    @include transition;
    &:focus,
    &:hover {
      border: 1px solid $primary;
    }
    &::placeholder {
      color: #b7b7b7;
    }
  }
  button {
    padding: 6.5px 10px;
    background: #373737;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    color: #ffffff;
    position: absolute;
    top: 50%;
    @include transformY;
    right: 15px;
  }
  &__outer {
    margin-bottom: 15px;

    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-bottom: 15px;
      display: block;
    }
  }
}
.space {
  height: 10px;
}
.check {
  display: flex;
  cursor: pointer;
  align-items: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  &__box {
    position: relative;
    width: 25px;
    min-width: 25px;
    height: 25px;
    box-sizing: border-box;
    border: 1px solid #cacaca;
    border-radius: 8px;
    @include transition;
    margin-right: 20px;
    &::before {
      position: absolute;
      content: "";
      top: 4px;
      left: 9px;
      width: 6px;
      height: 12px;
      border-bottom: 2px solid $black;
      border-right: 2px solid $black;
      transform: rotate(45deg);
      opacity: 0;
      @include transition;
    }
  }
  &.active {
    .check {
      &__box {
        background: $primary;
        border: 1px solid $primary;
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.error {
  color: #ef4047;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;
}