@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.footer {
  padding: 35px 0 30px 0;
  background: $dark-bg;
  &__inner {
    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 230px;
      margin-bottom: 32px;
      img {
        width: 100%;
      }
    }
    &-row {
      display: flex;
      justify-content: space-between;
    }
    &-links {
      display: flex;
      &-col {
        margin-right: 60px;
        h5 {
          margin-bottom: 32px;
          color: $white;
        }
        a {
          display: block;
          margin-bottom: 20px;

          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: $text-gray;
          @include transition;
          &:hover {
            color: $primary;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &-socials {
      h5 {
        margin-bottom: 32px;
        color: $white;
      }
      &-links {
        display: flex;
        align-items: center;
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $light-bg;
          width: 40px;
          height: 40px;
          border-radius: 10px;
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            color: $primary;
          }
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
  &__copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: $text-gray;
      font-weight: 400;
    }
    a {
      color: $text-gray;
      @include transition;
      &:hover {
        color: $primary;
      }
    }
  }
}
.divider {
  margin: 35px 0;
  height: 1px;
  background: $border-color;
}
// 1440px
@include xxxlg-xxxxlg {
  .footer {
    &__inner {
      &-logo {
        width: 180px;
      }
      &-links {
        &-col {
          a {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .footer {
    &__inner {
      &-logo {
        width: 160px;
      }
      &-socials {
        h5 {
          margin-bottom: 16px;
        }
      }
      &-links {
        &-col {
          h5 {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .divider {
    margin: 24px 0;
  }
}
// 840px
@include md-lg-extra {
  .footer {
    &__inner {
      &-socials {
        a {
          width: 35px;
          height: 35px;
          svg {
            width: 21px;
            height: 21px;
          }
        }
      }
    }
  }
}
// 540px
@include xsm-sm {
  .footer {
    padding: 22px 0;
    &__copy {
      flex-direction: column;
      align-items: flex-start;
      p {
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 16px;
      }
      a {
        font-size: 12px;
        line-height: 16px;
      }
    }
    &__inner {
      &-row {
        flex-direction: column;
      }
      &-logo {
        width: 140px;
      }
      &-links {
        flex-direction: column;
        &-col {
          margin-bottom: 20px;
        }
      }
    }
  }
}
