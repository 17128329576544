@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.earn {
  padding: 70px 0;
  &__head {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      color: $white;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.program {
  padding: 25px;
  box-sizing: border-box;
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  margin-bottom: 50px;
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    h5 {
      color: $white;
    }
    .select {
      width: 150px;
    }
  }
  &__inner {
    h5 {
      color: $white;
    }
  }
  &__items {
    margin: 0 -10px;
    display: flex;
  }
}
.stats {
  display: flex;
  margin-bottom: 50px;
  &__item {
    padding: 20px;
    display: flex;
    align-items: center;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    &-inner {
      display: flex;
      align-items: center;
    }
    &-btns {
      display: flex;
      align-items: center;
      button {
        margin-left: 20px;
        padding: 10px 15px;
      }
    }
    &:nth-child(1) {
      width: 245px;
      margin-right: 20px;
    }
    &:nth-child(2) {
      width: calc(100% - 265px);
      justify-content: space-between;
    }
    &-icon {
      width: 45px;
      height: 45px;
      color: #61e5fc;
      background: #373737;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    &-text {
      p {
        margin-bottom: 5px;
      }
      h4 {
        color: $white;
      }
    }
  }
}
.item {
  width: calc(33.3% - 20px);
  margin: 0 10px;
  padding: 30px 20px;
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  text-align: center;
  @include transition;
  &:hover {
    border: 1px solid #26cead;
  }
  &__icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: $primary;
    color: #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px auto;
    svg {
      width: 35px;
      height: 35px;
    }
  }
  h5 {
    margin-bottom: 15px;
  }
}
.container {
  max-width: 1090px;
  margin: 0 auto;
}
.input {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  input {
    width: 100%;
    padding: 20px 60px 20px 20px;
    border-radius: 10px;
    box-sizing: border-box;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 5px;
    color: #ffffff;
    @include transition;
    &:focus,&:hover {
      border: 1px solid $primary;
    }
  }
  button {
    position: absolute;
    top: 50%;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    width: 24px;
    height: 24px;
    @include transformY;
    color: #888;
    cursor: pointer;
    &:hover {
      color: $white;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .earn {
    padding: 50px 0;
  }
}
// 1240px
@include xlg-xxlg-extra {
  .stats {
    flex-direction: column;
    &__item {
      width: 100% !important;
      margin: 0 0 20px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
// 840px
@include md-lg-extra {
  .stats {
    &__item {
      &-inner {
        margin-bottom: 16px;
      }
      &:nth-child(2) {
        flex-direction: column;
        align-items: flex-start;
      }
      &-btns {
        width: 100%;
        button {
          margin: 0 16px 0 0;
        }
      }
    }
  }
  .program {
    &__items {
      flex-direction: column;
    }
  }
  .item {
    width: 100%;
    margin: 0 0 20px 0;
    &:last-child {
      margin: 0;
    }
  }
}
// 540px
@include xsm-sm {
  .program {
    padding: 0;
    border: unset;
    border-radius: 0;
    &__items {
      margin: 0;
    }
  }
  .earn {
    padding: 40px 0;
  }

  .stats {
    &__item {
      &-btns {
        flex-direction: column;
        button {
          width: 100%;
          margin: 0 0 16px 0;
          justify-content: center;
        }
      }
    }
  }
}
