@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.difference {
  padding: 90px 0;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #0e0e0e;
    border: 1px solid #373737;
    border-radius: 20px;
    padding: 40px;
    &-content {
      width: calc(45% - 15px);
      max-width: 460px;
      padding: 10px;
      box-sizing: border-box;
      h2 {
        color: $white;
        margin-bottom: 15px;
      }
    }
  }
}
.spin {
  width: calc(55% - 15px);
  &__inner {
    box-sizing: border-box;
    background: #181818;
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 20px;
    &-box {
      padding: 20px;
      border: 1px solid #373737;
      border-radius: 20px;
      margin-bottom: 30px;
    }
    &-action {
      width: 100%;
      button {
        width: 100%;
        text-align: center;
      }
    }
  }
  &__tool {
    width: 100%;
    padding: 10px;
    text-align: center;
    border: 1px solid #373737;
    border-radius: 20px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #cacaca;
      a {
        color: #cacaca;
        text-decoration: underline;
        @include transition;
        &:hover {
          color: $white;
        }
      }
    }
  }
  &Item {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
    &__inner {
      width: calc(100% - 100px);
      padding: 10px;
      box-sizing: border-box;
      background: #0e0e0e;
      border: 1px solid #373737;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-title {
        display: flex;
        align-items: center;
        width: 186px;
        img {
          width: 35px;
          height: 35px;
          object-fit: contain;
          margin-right: 10px;
        }
        p {
          font-weight: 400;
          color: $white;
        }
      }
      &-progress {
        position: relative;
        width: calc(100% - 200px);
        background: #181818;
        border-radius: 10px;
        height: 11px;
        box-sizing: border-box;
        span {
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 100%;
          display: block;
          background: linear-gradient(90deg, #26cead 0%, #ef4047 100%);
          border-radius: 10px;
        }
      }
    }
    &__counter {
      width: 80px;
      box-sizing: border-box;
      padding: 0 16px;
      background: #0e0e0e;
      border: 1px solid #373737;
      border-radius: 20px;
      margin-left: 20px;
      &-item {
        padding: 15px 0;
      }
      &-inner {
        display: inline-block;
        height: 46px;
        overflow: hidden;
        width: 100%;
        text-align: center;
      }
      &.active {
        border: 1px solid #7dffb7;
        p {
          background: #7dffb7;
          color: $black;
        }
      }
      p {
        padding: 10px 5px;
        background: #373737;
        border-radius: 10px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #cacaca;
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .spin {
    width: 55%;
    &__inner {
      padding: 20px;
    }
    &Item {
      &__inner {
        &-title {
          width: 140px;
        }
        &-progress {
          width: calc(100% - 140px);
        }
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .difference {
    &__inner {
      flex-direction: column;
      &-content {
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
        max-width: unset;
      }
    }
  }
  .spin {
    width: 100%;
  }
}
// 650px
@include sm-md-extra {
  .difference {
    &__inner {
      padding: 20px;
    }
  }
  .spin {
    &__inner {
      background: transparent;
      padding: 0;
      &-box {
        border: unset;
        padding: 0;
      }
    }
    &Item {
      margin-bottom: 10px;
      &__inner {
        width: calc(100% - 60px);
        &-progress {
          width: calc(100% - 120px);
        }
        &-title {
          width: 120px;
          p {
            font-size: 12px;
            line-height: 14px;
          }
          img {
            width: 25px;
            height: 25px;
            margin-right: 5px;
          }
        }
      }
      &__counter {
        padding: 0;
        margin-left: 10px;
        width: 50px;
        border: unset !important;
        background: transparent;
      }
    }
  }
}
