@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.why {
  padding: 105px 0 90px;
  position: relative;
  z-index: 1;
  &__head {
    margin-bottom: 60px;
    color: $white;
    text-align: center;
    h2 {
      margin-bottom: 12px;
    }
    p {
      max-width: 480px;
      margin: 0 auto;
      color: $text-gray;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
  }
  &Item {
    width: calc(33.3% - 20px);
    margin: 0 10px;
    background: $dark-bg;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 20px;
    text-align: center;
    border: 1px solid $dark-bg;
    @include transition;
    cursor: pointer;
    &:hover {
      border: 1px solid $primary;
    }
    &__icon {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 40px auto;
      background-color: $primary;
      border-radius: 50%;
      color: #181818;
      svg {
        width: 50%;
        height: 50%;
      }
    }
    &__content {
      h4 {
        color: $white;
        margin-bottom: 15px;
      }
      p {
        color: $text-gray;
        max-width: 335px;
        margin: 0 auto;
      }
    }
  }
}
@include xxxlg-xxxxlg {
  .why {
    padding: 80px 0;
    &Item {
      &__icon {
        width: 80px;
        height: 80px;
      }
      &__content {
        h4 {
          margin-bottom: 14px;
        }
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .why {
    &Item {
      padding: 25px 20px;
      &__icon {
        width: 60px;
        height: 60px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .why {
    padding: 60px 0;
    &__head {
      margin-bottom: 40px;
    }
    &__inner {
      flex-wrap: wrap;
    }
    &Item {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }
}
// 650px
@include sm-md-extra {
  .why {
    padding: 40px 0;
    &__head {
      margin-bottom: 24px;
    }
    &Item {
      width: calc(100% - 20px);
      padding: 30px 25px 60px 25px;
      &__icon {
        width: 105px;
        height: 105px;
        margin-bottom: 25px;
      }
      &__content {
        p {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}
