@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.manage {
  &__back {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #888888;
    @include transition;
    cursor: pointer;
    margin-bottom: 14px;
    &:hover {
      color: $white;
    }
    &-icon {
      float: right;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #373737;
      border-radius: 10px;
      margin-right: 15px;
      svg {
        width: 24px;
        height: 24px;
      }
      &:hover {
        color: $white;
      }
    }
  }
  &__inner {
    max-width: 813px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    h4 {
      margin-right: 10px;
      color: $white;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.table {
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  overflow-x: auto;
  /* width */
  &::-webkit-scrollbar {
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #373737;
    border-radius: 0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #888888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #888888;
  }

  &__status {
    display: flex;
    align-items: center;
    span {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
      margin-right: 15px;
      background: #888888;
      &.active {
        background: $primary;
      }
      &.paused {
        background: #ef4047;
      }
    }
  }
  &__name {
    display: flex;
    align-items: center;
    &-icon {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary;
      margin-right: 20px;
      svg {
        width: 16px;
        height: 16px;
        color: $black;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      cursor: pointer;
      &:hover {
        td {
          background: #373737;
        }
      }
    }
    th {
      border-bottom: 1px solid #373737;
    }
    th,
    td {
      padding: 15px 20px;
      font-weight: 400;
      white-space: nowrap;
      font-size: 14px;
      @include transition;
      line-height: 17px;
      color: $white;
      text-align: left;
      &:first-child {
        min-width: 280px;
        box-sizing: border-box;
      }
      &:last-child {
        width: 64px;
      }
    }
  }
  &__key {
    width: 300px;
    min-width: 300px;
    &-row {
      display: flex;
      align-items: center;
      button {
        width: 20px;
        height: 20px;
        color: #888;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.main {
  padding: 20px 0;
  &__inner {
    padding: 50px 0;
    margin-bottom: 50px;
    &:last-child {
      margin: 0;
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 35px;
    &-text {
      width: calc(100% - 220px);
      h4 {
        color: $white;
        margin-bottom: 10px;
      }
    }
    button {
      padding: 12.5px 10px;
      width: 193px;
      box-sizing: border-box;
      justify-content: center;
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.content {
  h4 {
    margin-bottom: 50px;
    text-align: center;
    color: $white;
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    button {
      padding: 15px;
      width: calc(50% - 10px);
    }
  }
  &__delete {
    h4 {
      margin-bottom: 10px;
    }
    p {
      max-width: 430px;
      margin: 0 auto 30px auto;
      text-align: center;
    }
  }
}
.note {
  display: flex;
  align-items: flex-start;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #ffdf8c;
  margin-bottom: 30px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.space {
  height: 10px;
}
