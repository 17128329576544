@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.dropdown {
  position: relative;
  &__btn {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-gray;
    background: transparent;
    cursor: pointer;
    &:hover {
      svg {
        color: $primary;
      }
    }
    svg {
      width: 100%;
      @include transition;
      height: 100%;
    }
  }
  &__content {
    position: absolute;
    bottom: 0;
    right: 100%;
    width: 150px;
    background: #0e0e0e;
    border: 1px solid #373737;
    padding: 5px 0;
    border-radius: 6px;
    button {
      display: block;
      padding: 10px 16px;
      color: $text-gray;
      text-align: left;
      cursor: pointer;
      @include transition;
      box-sizing: border-box;
      width: 100%;
      background: transparent;
      &:hover {
        background: #373737;
      }
    }
  }
}
