@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.intro {
  padding: 130px 0 105px 0;
  &__bg {
    position: absolute;
    top: 10%;
    left: 50%;
    width: 100%;
    max-width: 1900px;
    height: 100%;
    transform: translateX(-50%);
    pointer-events: none;
    overflow: hidden;

    &::before {
      position: absolute;
      top: 144px;
      left: 0;
      width: 320px;
      height: 320px;
      background: rgba(38, 206, 173, 0.3);
      filter: blur(250px);
      content: "";
      transform: translateX(-5%);
    }
    &::after {
      position: absolute;
      top: 450px;
      right: 0;
      width: 320px;
      height: 320px;
      background: rgba(239, 99, 64, 0.3);
      filter: blur(250px);
      content: "";
      transform: translateX(30%);
    }
  }
  &__shape {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1300px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  &__tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: $white;
    padding: 10px 38px;
    background: #181818;
    border-radius: 10px;
    margin-bottom: 40px;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      color: $primary;
    }
  }
  &__inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h1 {
      color: $white;
      margin-bottom: 30px;
    }
    p {
      color: $text-gray;
      max-width: 730px;
      line-height: 144%;
      margin: 0 auto 60px;
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .intro {
    padding: 130px 0 70px;
  }
}
// 1180px
@include xlg-xxlg {
  .intro {
    padding: 100px 0 50px;
    &__tag {
      margin-bottom: 24px;
    }
    &__inner {
      p {
        margin-bottom: 40px;
      }
      h1 {
        margin-bottom: 20px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .intro {
  }
}
// 840px
@include md-lg-extra {
  .intro {
    &__bg {
      &::before {
        top: 5%;
        width: 200px;
        height: 200px;
        filter: blur(200px);
      }
      &::after {
        top: 30%;
        width: 200px;
        height: 200px;
        filter: blur(200px);
      }
    }
    &__shape {
      width: 140%;
      min-width: 700px;
      top: 20%;
    }
  }
}
// 650px
@include sm-md-extra {
  .intro {
  }
}
// 540px
@include xsm-sm {
  .intro {
    padding: 100px 0 30px;
    &__tag {
      margin-bottom: 20px;
      padding: 10px 24px;
    }
    &__inner {
      p {
        margin-bottom: 30px;
      }
    }
  }
}
