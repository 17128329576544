@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.layout {
  padding: 70px 0;
  max-width: 1090px;
  margin: 0 auto;
}
// 1380px
@include xxlg-xxxlg {
  .layout {
    padding: 50px 0;
  }
}
