@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.why {
  padding: 90px 0;
  position: relative;
  z-index: 1;
  &__head {
    margin-bottom: 50px;
    color: $white;
    h2 {
      margin-bottom: 12px;
    }
  }
  &__inner {
    display: flex;
    margin: 0 -10px;
  }
  &Item {
    width: calc(25% - 20px);
    margin: 0 10px;
    background: $dark-bg;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 35px 25px;
    text-align: center;
    &__icon {
      width: 105px;
      height: 105px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 24px auto;
      color: $primary;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &__content {
      h4 {
        color: $white;
        margin-bottom: 20px;
      }
      p {
        color: $text-gray;
        max-width: 300px;
        margin: 0 auto;
      }
    }
  }
}
@include xxxlg-xxxxlg {
  .why {
    padding: 70px 0;
    &Item {
      &__icon {
        width: 80px;
        height: 80px;
      }
      &__content {
        h4 {
          margin-bottom: 14px;
        }
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .why {
    &Item {
      padding: 25px 20px;
      &__icon {
        width: 60px;
        height: 60px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .why {
    &__head {
      margin-bottom: 40px;
    }
    &__inner {
      flex-wrap: wrap;
    }
    &Item {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }
}
// 650px
@include sm-md-extra {
  .why {
    padding: 85px 0;
    &Item {
      width: calc(100% - 20px);
      padding: 30px 25px 60px 25px;
      &__icon {
        width: 105px;
        height: 105px;
        margin-bottom: 25px;
      }
      &__content {
        p {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }
}
