@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.container {
  max-width: 1090px;
  margin: 0 auto;
}
.usage {
  padding: 20px 0;
  &__inner {
    padding: 50px 0;
  }
}
// 1540px
@include xxxxlg-xxxxxlg {
  .usage {
    &__inner {
      padding: 24px 0;
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .usage {
    &__inner {
      padding: 0;
    }
  }
}
// 1180px
@include xlg-xxlg {
  .usage {
    padding: 40px 0;
    &__inner {
      padding: 0;
    }
  }
}
