// colors
$primary: #26cead;
$primary-dark: #699f8b;
$secondary: #ef4047;
$secondary-dark: #ac6f69;
$body-bg: #000000;
$dark-bg: #0e0e0e;
$light-bg: #373737;
$text-gray: #cacaca;
$white: #ffffff;
$black: #000;
$border-color: #2e2e2e;
$mainFont: "Inter", sans-serif;
