@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.block {
  h4 {
    text-align: center;
    color: $white;
    margin-bottom: 40px;
  }
  &__btns {
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
    button {
      width: 100%;
      padding: 15px;
      margin-bottom: 10px;
    }
  }
  &__head {
    text-align: center;
    margin-bottom: 30px;
    h4 {
      margin-bottom: 10px;
      color: $white;
    }
    p {
      strong {
        color: $white;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  &__qr {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    &-price {
      display: flex;
      color: $white;
      align-items: center;
      margin-bottom: 20px;
      h5 {
        margin-right: 5px;
      }
      p {
        small {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: $text-gray;
          display: inline-block;
          margin-left: 10px;
        }
      }
    }
    &-image {
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 134px;
      height: 134px;
      background: $white;
      border-radius: 10px;
    }
    &-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 134px;
      height: 134px;
      svg {
        width: 90px;
        height: 90px;
      }
    }
  }
  &__tool {
    text-align: center;
    margin-bottom: 25px;
  }
  &__icon {
    width: 120px;
    height: 120px;
    margin: 0 auto 50px auto;
    color: $primary;
  }
}
.input {
  &__outer {
    position: relative;
    margin-bottom: 25px;
    &.error {
      input {
        border: 1px solid #ef4047;
      }
    }
    &:hover {
      input {
        border-color: $primary;
      }
    }
    &.w-50 {
      width: calc(50% - 10px);
    }
    &.card {
      input {
        padding-left: 59px;
      }
    }
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-bottom: 12px;
      display: inline-block;
    }
  }
  position: relative;
  input {
    box-sizing: border-box;
    background: #181818;
    border: 1px solid #373737;
    @include transition;
    border-radius: 10px;
    padding: 16px 15px;
    width: 100%;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $white;
    @include transition;
    &:focus,&:hover {
      border: 1px solid $primary;
    }
    &::placeholder {
      color: #b7b7b7;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }
}
.address {
  position: relative;
  padding: 15px 85px 15px 16px;
  margin-bottom: 20px;
  background: #181818;
  border: 1px solid #373737;
  border-radius: 10px;
  word-break: break-all;
  color: $white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  button {
    position: absolute;
    top: 50%;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    width: 24px;
    height: 24px;
    @include transformY;
    color: #888;
    cursor: pointer;
    &:hover {
      color: $white;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.space {
  height: 20px;
}
.note {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #ffdf8c;
  margin-bottom: 30px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
