@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.earn {
  padding: 70px 0;
  &__head {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      color: $white;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.program {
  padding: 25px;
  box-sizing: border-box;
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  margin-bottom: 50px;
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    h5 {
      color: $white;
    }
    .select {
      width: 150px;
    }
  }
  &__inner {
    h5 {
      color: $white;
    }
  }
  &__items {
    margin: 0 -10px;
    display: flex;
  }
}
.stats {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #181818;
  border: 1px solid #373737;
  border-radius: 10px;
  &__inner {
    display: flex;
    align-items: center;
  }
  &__btns {
    display: flex;
    align-items: center;
    button {
      margin-left: 20px;
      padding: 10px 15px;
    }
  }

  &__icon {
    width: 45px;
    height: 45px;
    color: #61e5fc;
    background: #373737;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  &__text {
    p {
      margin-bottom: 5px;
    }
    h4 {
      color: $white;
    }
  }
}
.tip {
  margin-bottom: 50px;
  position: relative;
  padding-left: 120px;
  background: #f18e92;
  border-radius: 10px;
  min-height: 180px;
  &__bant {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__inner {
    padding: 30px 40px;
    display: flex;
    align-items: flex-start;
    &-content {
      flex: 1;
      color: $black;
      h4 {
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 20px;
        line-height: 23px !important;
      }
      button {
        background: $black;
      }
    }
  }
  .toggle {
    margin-left: 20px;
  }
}
.container {
  max-width: 1090px;
  margin: 0 auto;
}
// 1380px
@include xxlg-xxxlg {
  .earn {
    padding: 50px 0;
  }
}
// 1240px
@include xlg-xxlg-extra {
}
// 840px
@include md-lg-extra {
  .stats {
    flex-direction: column;
    align-items: flex-start;
    &__inner {
      margin-bottom: 16px;
    }
    &__btns {
      width: 100%;
      button {
        margin: 0 16px 0 0;
      }
    }
  }
  .tip {
    padding-left: 0;
    &__bant {
      display: none;
    }
  }
}
// 540px
@include xsm-sm {
  .earn {
    padding: 40px 0;
  }
  .program {
    padding-inline: 0;
    border: unset;
    background: transparent;
  }
  .stats {
    &__btns {
      flex-direction: column;
      button {
        width: 100%;
        margin: 0 0 16px 0;
        justify-content: center;
      }
    }
  }
  .tip {
    &__inner {
      padding: 30px 20px;
      flex-direction: column;
    }
    .toggle {
      order: -1;
      margin-left: auto;
      margin-bottom: 16px;
    }
  }
}
