@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.dashboard {
  padding: 20px 0;
  &__head {
    background: $primary;
    border-radius: 10px;
    padding: 50px 20px 130px 20px;
    color: $black;
    &-inner {
      max-width: 1090px;
      margin: 0 auto;
    }
  }
  &__body {
    padding: 0 20px;
    margin-top: -80px;
    &-inner {
      max-width: 1090px;
      margin: 0 auto;
      background: #0e0e0e;
      border: 1px solid #373737;
      border-radius: 10px 10px;
      padding: 40px 30px;
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .dashboard {
    &__body {
      padding: 0;
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .dashboard {
    &__body {
      &-inner {
        padding: 30px 20px;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .dashboard {
    margin: 0 -20px;
    padding: 20px 0 0 0;
    border-radius: 12px 12px 0 0;
    &__head {
      border-radius: 0;
    }
  }
}
// 930px
@include md-lg {
}
// 540px
@include xsm-sm {
  .dashboard {
    margin: 0 -13px;
    &__head {
      padding-left: 13px;
      padding-right: 13px;
    }
    &__body {
      &-inner {
        padding: 30px 13px;
      }
    }
  }
}
