@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.captcha-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  img {
    width: 100%;
    max-width: 300px;
  }
}
.auth {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 100px 20px;
  overflow: hidden;
  position: relative;
  &.center {
    padding-top: 170px;
  }
  &__shape {
    position: absolute;
    top: 160px;
    right: 20px;
    width: 45%;
    max-width: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 010%;
      width: 320px;
      height: 320px;
      background: rgba(239, 99, 64, 0.3);
      filter: blur(200px);
      z-index: -1;
    }
    &::after {
      position: absolute;
      content: "";
      bottom: 15%;
      left: -10%;
      width: 320px;
      border-radius: 50%;
      height: 320px;
      background: rgba(38, 206, 173, 0.3);
      z-index: -1;
      filter: blur(200px);
    }
    img {
      width: 100%;
    }
  }
  &__inner {
    width: 100%;
    margin: 0 auto auto auto;
    max-width: 520px;
    box-sizing: border-box;
    padding: 40px;
    background: rgba(24, 24, 24, 0.64);
    border: 1px solid #373737;
    backdrop-filter: blur(25px);
    border-radius: 20px;
    position: relative;
    z-index: 1;

    &-logo {
      width: 176px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 40px auto;
      img {
        width: 100%;
      }
    }
    h3 {
      text-align: center;
    }
    &-btn {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      button {
        width: 100%;
      }
      button:disabled {
        background: #4a4a4a;
        color: #b3b3b3;
      }
    }
    &-head {
      margin-bottom: 50px;
      text-align: center;
      h3 {
        color: $white;
        margin-bottom: 20px;
      }
      p {
        a {
          color: $primary;
        }
      }
    }
    &-back {
      text-align: center;
      font-weight: 400;
      a {
        color: $primary;
      }
    }
    &-forget {
      margin-bottom: 30px;
      text-align: right;
      a {
        color: $primary;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
      }
    }
    &-foot {
      text-align: center;
      p {
        a {
          color: $white;
          @include transition;
          &:hover {
            color: $primary;
          }
        }
      }
    }
    &-icon {
      color: $primary;
      width: 158px;
      height: 158px;
      margin: 0 auto 5px auto;
    }
    &-mail {
      margin-bottom: 28px;
      text-align: center;
      h3 {
        color: $white;
        margin-bottom: 10px;
      }
    }
  }
}
.note {
  display: flex;
  align-items: flex-start;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #ffdf8c;
  margin-bottom: 30px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.space {
  height: 10px;
}
// 650px
@include sm-md-extra {
  .auth {
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    &.center {
      padding: 0;
    }
    &__inner {
      margin: auto 0 0 0;
      max-width: unset;
      box-sizing: border-box;
      overflow-y: auto;
      min-height: 85%;
      padding: 40px 13px 13px 13px;
      &-logo {
        width: 140px;
        margin-bottom: 30px;
      }
    }
  }
}
