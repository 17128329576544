@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.settings {
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  padding: 30px 25px 25px 25px;
  margin-bottom: 50px;
  &:last-child {
    margin: 0;
  }
  &__title {
    margin-bottom: 20px;
    color: $white;
  }
  &__content {
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 20px;
  }
}
.check {
  max-width: 850px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:last-child {
    margin: 0;
  }
  &__title {
    width: calc(100% - 80px);
  }
  &__box {
    width: 60px;
    height: 34px;
    position: relative;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      cursor: pointer;
      opacity: 0;
      &:checked ~ span {
        background-color: #1f9e85;
        &::before {
          transform: translate(27px, -50%);
        }
      }
    }
    span {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #373737;
      border-radius: 20px;
      transition: 0.3s ease;
      &::before {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        content: "";
        background-color: $white;
        border-radius: 50%;
        transition: 0.3s ease;
      }
    }
  }
  h6 {
    margin-bottom: 10px;
    color: $white;
  }
  p {
    color: #aeaeae;
  }
}
// 840px
@include md-lg-extra {
  .settings {
    padding: 25px 20px;
    &__content {
      padding: 15px;
    }
  }
}
// 540px
@include xsm-sm {
  .settings {
    padding: 20px 15px;
    &__content {
      padding: 15px;
    }
  }
  .check {
    &__title {
      width: calc(100% - 70px);
      h6 {
        font-size: 14px;
        line-height: 17px;
      }
      p {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
