@import "./mixin.scss";
@import "./variables.scss";
html,
body {
  scroll-behavior: smooth;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
}
button {
  outline: none;
}
button:focus {
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $light-bg;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0e0e0e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0e0e0e;
}
/*** 
====================================================================
Global Settings
====================================================================
   ***/
body {
  font-family: $mainFont;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: $text-gray;
  background: $body-bg;
  &.active {
    overflow: hidden;
  }
}
a {
  text-decoration: none;
  opacity: 1;
  transition: 0.3s ease;
  color: $white;
  cursor: pointer;
}
ul {
  li {
    list-style: none;
  }
}
.auto__container {
  position: relative;
  max-width: 1480px;
  margin: 0 auto;
  padding: 0 30px;
}
.main {
  padding-top: 101px;

}
// 1440px
@include xxxlg-xxxxlg {
  .auto__container {
    max-width: 1240px;
  }
  .main {
    padding-top: 85px;
  }
  body {
    line-height: 22px;
    font-size: 14px;
  }
}
// 1240px
@include xlg-xxlg-extra {
  .auto__container {
    max-width: 1080px;
    padding: 0 20px;
  }
  .main {
    padding-top: 84px;
  }
}
// 840px
@include md-lg-extra {
  .main {
    padding-top: 70px;
  }
  body.active {
    overflow: hidden;
  }
}
// 650px
@mixin sm-md-extra {
  .main {
    padding-top: 64px;
  }
}
// 540px
@include xsm-sm {
  .auto__container {
    padding: 0 13px;
  }
}
.anchor {
  position: relative;
  top: -80px;
}
