@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";

.check {
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    &:checked + label {
      background: $primary;
      &::before {
        left: 32px;
      }
    }
  }
  label {
    width: 61px;
    height: 34px;
    border-radius: 20px;
    display: block;
    background: #373737;
    position: relative;
    @include transition;
    &::before {
      position: absolute;
      content: "";
      top: 50%;
      @include transformY;
      left: 5px;
      width: 24px;
      @include transition;
      height: 24px;
      background: $white;
      border-radius: 20px;
    }
  }
}

.check-black {
  position: relative;
  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
    &:checked + label {
      background: $black;
      &::before {
        left: 32px;
      }
    }
  }
  label {
    width: 61px;
    height: 34px;
    border-radius: 20px;
    display: block;
    background: #373737;
    position: relative;
    @include transition;
    &::before {
      position: absolute;
      content: "";
      top: 50%;
      @include transformY;
      left: 5px;
      width: 24px;
      @include transition;
      height: 24px;
      background: $white;
      border-radius: 20px;
    }
  }
}
