@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.info {
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  padding: 30px 25px;
  margin-bottom: 20px;
  &:last-child {
    margin: 0;
  }
  &__title {
    margin-bottom: 13px;
    h6 {
      color: $white;
      margin-bottom: 12px;
    }
  }
  &__row {
    display: flex;
    margin: 0 -10px;
  }
  &__foot {
    display: flex;
    margin: 0 -10px;
  }
  &Item {
    width: calc(29% - 20px);
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    margin: 10px;
    padding: 22px 20px;
    color: $white;
    &:first-child {
      width: calc(40% - 20px);
    }
    &:last-child {
      width: calc(31% - 20px);
    }
    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-weight: 500;
      }
      h6 {
        color: #26cead;
      }
    }
    & > p {
      margin-bottom: 10px;
      color: #aeaeae;
    }
    .select {
      min-width: 110px;
    }
  }
}
.rank {
  width: calc(30% - 20px);
  background: #181818;
  border: 1px solid #373737;
  border-radius: 10px;
  margin: 10px;
  padding: 15px 20px;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &__col {
    display: flex;
    flex-direction: column;
  }
  p {
    margin-bottom: 10px;
    color: #aeaeae;
  }
  .select {
    min-width: 110px;
  }
}
.date {
  width: calc(70% - 20px);
  background: #181818;
  border: 1px solid #373737;
  border-radius: 10px;
  margin: 10px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &__row {
    display: flex;
    justify-content: space-between;
    //width: calc(100% - 140px);
    width: 100%;
    //max-width: 440px;
  }
  &__col {
    color: $white;
    width: calc(50% - 3px);
    p {
      margin-bottom: 10px;
      color: #aeaeae;
    }
  }
}
.button {
  font-family: $mainFont;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $text-gray;
  background: #181818;
  border: 1px solid #373737;
  border-radius: 10px;
  padding: 5px 18px;
  cursor: pointer;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  &.last {
    svg {
      margin: 0 0 0 10px;
    }
  }
  &:hover {
    border-color: $text-gray;
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}
// 930px
@include md-lg {
  .info {
    &__row {
      margin: 0 -6px;
    }
    &Item {
      margin: 6px;
      width: calc(33.3% - 12px) !important;
      padding: 16px;
    }
  }
}
// 840px
@include md-lg-extra {
  .info {
    padding: 25px 20px;
    &__title {
      margin-bottom: 24px;
    }
    &__row {
      margin: 0;
      flex-direction: column;
    }
    &__foot {
      margin: 0;
      flex-direction: column;
    }
    &Item {
      margin: 0 0 20px 0;
      width: 100% !important;
      padding: 15px;
    }
  }
  .rank {
    width: 100%;
    margin: 0 0 20px 0;
    padding: 15px;
  }
  .date {
    width: 100%;
    margin: 0;
    padding: 15px;
    &__row {
      //width: calc(100% - 130px);
    }
  }
}
// 540px
@include xsm-sm {
  .info {
    padding: 20px 15px;
    &Item {
      margin-bottom: 15px;
    }
  }
  .date {
    flex-direction: column;
    &__row {
      width: 100%;
      flex-direction: column;
      margin-bottom: 20px;
    }
    &__col {
      width: 100%;
      margin-bottom: 15px;
      &:last-child {
        margin: 0;
      }
      p {
        margin-bottom: 5px;
      }
    }
  }
}
