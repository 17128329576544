@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.header {
  border-bottom: 1px solid #181818;
  padding: 20px 0;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title {
      display: flex;
      align-items: center;
      h4 {
        margin-right: 15px;
        color: $white;
      }
    }
    &-type {
      padding: 8px 15px;
      background: rgba(38, 206, 173, 0.08);
      border-radius: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #26cead;
    }
    &-action {
      display: flex;
      align-items: center;
    }
  }
  &__mob {
    display: none;
  }
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &__title {
    width: 100%;
    height: 100%;
    background: $primary;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      @include fullImage;
    }
  }
}
.balance {
  display: flex;
  align-items: center;
  padding: 15px;
  width: 296px;
  box-sizing: border-box;
  border: 1px solid #373737;
  border-radius: 10px;
  margin-right: 30px;
  @include transition;
  cursor: pointer;
  &:hover {
    border: 1px solid $primary;
    .balance__icon {
      color: $primary;
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    color: $text-gray;
    svg {
      width: 100%;
      height: 100%;
      @include transition;
    }
  }
  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $text-gray;
    width: calc(100% - 39px);
    span {
      color: $white;
    }
  }
}
// 1540px
@include xxxxlg-xxxxxlg {
}
// 1380px
@include xxlg-xxxlg {
}
// 1180px
@include xlg-xxlg {
  .header {
    padding: 0;
    &__mob {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: $black;
      height: 65px;
      z-index: 100;
      &-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        img {
          width: 100%;
        }
      }
    }
    &__inner {
      padding: 80px 0 13px 0;
      &-action {
        display: none;
      }
    }
  }
  .burger {
    display: block;
    cursor: pointer;
    @include transition;
    position: relative;
    width: 24px;
    height: 24px;
    &:hover {
      opacity: 0.8;
    }
    &::before {
      top: 16%;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: $white;
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      left: 50%;
      @include transformX;
    }
    span {
      top: 50%;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: $white;
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      @include transform;
      @include transition;
    }
    &::after {
      bottom: 16%;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: $white;
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      left: 50%;
      @include transformX;
    }
    &.active {
      &::before {
        top: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
      }
      span {
        opacity: 0;
      }
      &::after {
        bottom: 50%;
        -webkit-transform: translate(-50%, 50%) rotate(-45deg);
        transform: translate(-50%, 50%) rotate(-45deg);
      }
    }
  }
}
// 650px
@include sm-md-extra {
  .header {
    &__mob {
      padding: 15px 13px;
      &-logo {
        width: 140px;
      }
    }
  }
}
