@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.detail {
  padding-top: 20px;
  &__back {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #888888;
    @include transition;
    cursor: pointer;
    margin-bottom: 50px;
    &:hover {
      color: $white;
    }
    &-icon {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #373737;
      border-radius: 10px;
      margin-right: 15px;
      color: $white;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__inner {
    max-width: 1091px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
}
// 1180px
@include xlg-xxlg {
  .detail {
    &__back {
      margin-bottom: 30px;
    }
  }
}
// 840px
@include md-lg-extra {
}
