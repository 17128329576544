@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";

.error__btn {
  @include transition;
  padding: 13px 55px;
  font-family: $mainFont;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $white;
  border-radius: 10px;
  cursor: pointer;
  background: #373737;
  border: 1px solid #373737;
  color: #fff;
  margin-left: 10px;
  &:hover {
    border: 1px solid $primary;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
.detail {
  padding-top: 20px;
  &__back {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #888888;
    @include transition;
    cursor: pointer;
    margin-bottom: 50px;
    &:hover {
      color: $white;
    }
    &-icon {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #373737;
      border-radius: 10px;
      margin-right: 15px;
      color: $white;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__inner {
    max-width: 1091px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    h4 {
      margin-right: 10px;
      color: $white;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.block {
  margin-bottom: 25px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    h4 {
      color: $white;
    }
  }
  &__inner {
    background: #0e0e0e;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 25px;
  }
  &Item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
    &__title {
      display: flex;
      align-items: center;
      position: relative;
      width: 240px;
      p {
        margin-left: 10px;
      }
    }
    &__info {
      width: calc(100% - 240px);
      display: flex;
      align-items: center;
      button {
        width: 18px;
        height: 18px;
        background: transparent;
        cursor: pointer;
        @include transition;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #888888;
        margin-left: 10px;
        &:hover {
          color: $white;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
      p {
        color: $white;
        display: block;
        max-width: calc(100% - 28px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__status {
      display: flex;
      align-items: center;
      width: calc(100% - 240px);
      text-transform: capitalize;
      svg {
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
      span {
        color: #888888;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        &.success {
          color: #47f48a;
        }
        &.failed {
          color: $secondary;
        }
      }
    }
    &__text {
      display: flex;
      align-items: center;
      width: calc(100% - 240px);
      color: $white;
      &-log {
        p {
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      svg {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        color: #888888;
      }
    }
    &__collapse {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: calc(100% - 240px);
      color: $white;
      p {
        text-decoration: underline;
      }
      button {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        color: #61e5fc;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        @include transition;
        &:hover {
          color: $white;
        }
      }
    }
    &__items {
      overflow: hidden;
    }
  }
}
// 1180px
@include xlg-xxlg {
  .detail {
    &__back {
      margin-bottom: 30px;
    }
  }
}
// 840px
@include md-lg-extra {
  .block {
    &Item {
      flex-direction: column;
      &__title {
        width: 100%;
        margin-bottom: 10px;
      }
      &__info {
        width: 100%;
      }
      &__text {
        width: 100%;
      }
      &__collapse {
        width: 100%;
      }
      &__status {
        width: 100%;
      }
    }
  }
}
