@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.access {
  margin-bottom: 40px;
  &__row {
    display: flex;
    margin: 0 -10px;
  }
  &__title {
    margin-bottom: 20px;
  }
  &Item {
    width: calc(33.3% - 20px);
    margin: 0 10px;
    box-sizing: border-box;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 25px;
    display: block;
    @include transition;
    &:hover {
      border: 1px solid $primary;
      .accessItem__content-text {
        svg {
          color: $primary;
          transform: translateX(5px);
        }
      }
    }
    &__icon {
      width: 30px;
      height: 30px;
      display: flex;
      color: $primary;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &__content {
      h5 {
        color: $white;
        margin-bottom: 10px;
      }
      &-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          color: $text-gray;
        }
        svg {
          width: 24px;
          height: 24px;
          color: $white;
          @include transition;
        }
      }
    }
  }
}
// 930px
@include md-lg {
  .access {
    &__row {
      flex-direction: column;
      margin: 0;
    }
    &Item {
      width: 100% !important;
      margin: 0 0 20px 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
