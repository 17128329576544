@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.join {
  padding: 90px 0 180px 0;
  &__shape1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 374px;
    z-index: -1;
    position: absolute;
    top: -20px;
    right: -140px;
    transform: rotate(15deg);

    filter: blur(5px);

    img {
      width: 100%;
    }
  }
  &__shape2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 470px;
    position: absolute;
    bottom: 55px;
    filter: blur(7.5px);
    left: -80px;
    transform: rotate(15deg);
    z-index: -1;
    img {
      width: 100%;
    }
  }
  &__inner {
    background: $dark-bg;
    overflow: hidden;
    padding: 60px;
    position: relative;
    z-index: 1;
    border-radius: 20px;
    &-content {
      color: $white;
      text-align: center;
      h2 {
        margin-bottom: 10px;
      }
      p {
        a {
          text-decoration: underline;
        }
      }
      &-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 50px auto 10px auto;
        max-width: 482px;
        button {
          width: calc(50% - 10px);
          padding: 15.5px;
        }
      }
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .join {
    padding: 70px 0 140px 0;
    &__shape1 {
      width: 280px;
      right: -100px;
    }
    &__shape2 {
      width: 380px;
      left: -60px;
    }
  }
}
// 1024px
@include lg-xlg {
  .join {
    padding: 85px 0 170px 0;
    &__shape1 {
      right: -220px;
    }
    &__shape2 {
      left: -300px;
    }
    &__inner {
      padding: 60px 20px 40px 20px;
    }
  }
}

// 650px
@include sm-md-extra {
  .join {
    &__inner {
      h2 {
        font-size: 20px;
        line-height: 30px;
      }
      p {
        font-size: 14px !important;
        line-height: 21px !important;
      }
      &-content {
        &-btns {
          flex-direction: column;
          max-width: 300px;
          margin-bottom: 16px;
          button {
            margin-bottom: 20px;
            width: 100%;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
