@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.counter {
  padding: 10px 15px;

  border: 1px solid #888888;
  border-radius: 10px;
  span {
    color: $white;
  }
}
