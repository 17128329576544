@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 24px 0;
  z-index: 10;
  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $black;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 230px;
      @include transition;
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 100%;
      }
    }
    &-action {
      display: flex;
      align-items: center;
    }
  }
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &__title {
    width: 100%;
    height: 100%;
    background: $primary;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      @include fullImage;
    }
  }
}
.balance {
  display: flex;
  align-items: center;
  padding: 15px;
  width: 296px;
  box-sizing: border-box;
  border: 1px solid #373737;
  border-radius: 10px;
  margin-right: 30px;
  @include transition;
  cursor: pointer;
  &:hover {
    border: 1px solid $primary;
    .balance__icon {
      color: $primary;
    }
  }
  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    color: #888888;
    svg {
      width: 100%;
      height: 100%;
      @include transition;
    }
  }
  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $text-gray;
    width: calc(100% - 39px);
    span {
      color: $white;
    }
  }
}
// 1440px
@include xxxlg-xxxxlg {
  .header {
    padding: 20px 0;
    &__inner {
      &-logo {
        width: 180px;
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .header {
    &__inner {
      &-logo {
        width: 160px;
      }
    }
  }
  .nav {
    width: 66%;
    &__inner {
      &-links {
        a {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
// 1024px
@include lg-xlg {
}
// 840px
@include md-lg-extra {
  .balance {
    display: none;
  }
}
// 540px
@include xsm-sm {
  .header {
    &__inner {
      &-logo {
        width: 140px;
      }
    }
  }
}
