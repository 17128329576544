@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.note {
  display: flex;
  align-items: flex-start;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #ffdf8c;
  margin-bottom: 5px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.bill {
  padding: 20px 0;
  &__inner {
    padding: 50px 0;
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    &-text {
      h4 {
        color: $white;
        margin-bottom: 10px;
      }
    }
    &-btns {
      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: $text-gray;
        @include transition;
        &:hover {
          color: $white;
        }
      }
      button {
        margin-left: 15px;
        padding: 13px 28px;
      }
    }
  }
  &__info {
    padding: 50px 25px;
    background: #0e0e0e;
    border: 1px solid #373737;
    border-radius: 10px;
    display: flex;
    margin: 0 0 50px 0;
    &-item {
      width: calc(20% - 20px);
      margin: 0 10px;
      border-right: 1px solid #373737;
      &:nth-child(1) {
        width: 25%;
      }
      &:nth-child(2) {
        width: 35%;
        padding-left: 20px;
      }
      h6 {
        color: $white;
        margin-bottom: 10px;
      }
      &-title {
        color: $text-gray;
        margin-bottom: 10px;
      }
      h5 {
        color: $white;
        font-weight: 400;
      }
      &-price {
        display: flex;
        align-items: flex-end;
        h4 {
          color: $white;
        }
      }
    }
    &-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(45% - 20px);
      margin: 0 10px;
      padding-left: 20px;
      &-text {
        p {
          color: $text-gray;
          margin-bottom: 10px;
        }
        h5 {
          color: $white;
          font-weight: 400;
        }
      }
      button {
        font-weight: 400;
        background: transparent;
        font-size: 16px;
        line-height: 19px;
        cursor: pointer;
        letter-spacing: 0.02em;
        text-decoration-line: underline;
        color: #cacaca;
        @include transition;
        &:hover {
          color: $white;
        }
      }
    }
  }
}
.container {
  max-width: 1090px;
  margin: 0 auto;
}
// 1540px
@include xxxxlg-xxxxxlg {
  .bill {
    &__inner {
      padding: 24px 0;
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .bill {
    &__inner {
      padding: 0;
    }
  }
}
// 1180px
@include xlg-xxlg {
  .bill {
    padding: 40px 0;
    &__inner {
      padding: 0;
    }
  }
}
// 1024px
@include lg-xlg {
  .bill {
    &__head {
      flex-direction: column;
      align-items: flex-start;
      &-text {
        margin-bottom: 40px;
        width: 100%;
      }
      &-btns {
        display: flex;
        align-items: center;
        button {
          order: -1;
          margin: 0 10px 0 0;
        }
      }
    }
    &__info {
      flex-wrap: wrap;
      padding: 25px 10px;
      &-item {
        width: calc(50% - 20px) !important;
        margin: 10px;
        padding: 0 !important;
        &:nth-child(2) {
          border-right: unset;
        }
      }
      &-flex {
        width: calc(100% - 20px);
        margin: 10px;
        padding: 0;
      }
    }
  }

}
// 540px
@include xsm-sm {
  .bill {
    &__info {
      &-flex {
        flex-direction: column;
        align-items: center;
        &-text {
          width: 100%;
          margin-bottom: 40px;
        }
      }
    }
  }
}
