@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.error-message {
  color: #ef4047;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.radio {
  border: 1px solid rgba(55, 55, 55, 1);
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(24, 24, 24, 1);
  margin: 15px;
}
.radio:hover {
  border: 1px solid rgba(38, 206, 173, 1);
  cursor: pointer;
}

.radio-button {
  cursor: pointer;
  padding-right: 25px;
}
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input {
  font-size: 1rem;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  @include transition;
  &:focus {
    border: 1px solid $primary;
  }
}

.radio-button__input {
  opacity: 0;
  position: absolute;
}

.radio-button__control {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  background-color: inherit;
  color: #017b5f;
  border: 2px solid #666;
  border-radius: 24px;
}

.radio-button__input:checked + .radio-button__control:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform;
  width: 10px;
  height: 10px;
  background-color: rgba(38, 206, 173, 1);
  border-radius: 12px;
}

.radio-button__input:checked + .radio-button__control {
  border-color: rgba(38, 206, 173, 1);
}

.form {
  h4 {
    text-align: center;
    color: $white;
    margin-bottom: 40px;
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    button {
      width: calc(50% - 10px);
    }
  }
}
.input {
  &__outer {
    position: relative;
    margin-bottom: 25px;
    &.error {
      input {
        border: 1px solid #ef4047;
      }
    }
    &.w-50 {
      width: calc(50% - 10px);
    }
    &.card {
      input {
        padding-left: 59px;
      }
    }
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-bottom: 12px;
      display: inline-block;
    }
  }
  position: relative;
  input {
    box-sizing: border-box;
    background: #181818;
    border: 1px solid #373737;
    @include transition;
    border-radius: 10px;
    padding: 16px 15px;
    width: 100%;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $white;
    &::placeholder {
      color: #b7b7b7;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
  }
}
.space {
  height: 20px;
}
.tool {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  color: #ef4047;
  font-size: 12px;
  line-height: 16px;
}
.icon {
  position: absolute;
  top: 50%;
  left: 15px;
  @include transformY;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  svg,
  img {
    width: 100%;
    height: 100%;
  }
}
