h1 {
  font-weight: 600;
  font-size: 85px;
  line-height: 103px;
  span {
    background: linear-gradient(90deg, #26cead 0%, #ef4047 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  &.sm {
    font-size: 65px;
    line-height: 79px;
  }
}
h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}
h3 {
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
}
h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  &.big {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
  }
}
h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
}
h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
p {
  &.big {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  &.sm {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
.linenumber {
  font-style: normal !important;
}
// 1440px
@include xxxlg-xxxxlg {
  h1 {
    font-size: 64px;
    line-height: 72px;
    &.sm {
      font-size: 42px;
      line-height: 52px;
    }
  }

  h2 {
    font-size: 32px;
    line-height: 42px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
  }
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
  h5 {
    font-size: 16px;
    line-height: 20px;
  }
  h6 {
    font-size: 16px;
    line-height: 19px;
  }
  p {
    &.big {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  h1 {
    font-size: 54px;
    line-height: 64px;
  }
  h2 {
    font-size: 30px;
    line-height: 36px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
}
// 1024px
@include lg-xlg {
  h1 {
    font-size: 45px;
    line-height: 52px;
    &.sm {
      font-size: 40px;
      line-height: 50px;
    }
  }
  h4 {
    font-size: 22px;
    line-height: 26px;
  }
}
// 395px
@include xxxsm-xxsm {
  h4.big {
    font-size: 20px;
    line-height: 24px;
  }
}
.react-datepicker {
  background: #0e0e0e !important;
  border: 1px solid #373737 !important;
  color: $white !important;
  border-radius: 10px !important;
}
.react-datepicker__header {
  background: #0e0e0e !important;
  color: #ffffff !important;
  padding: 25px 0 0 0 !important;
  border: unset !important;
}
.react-datepicker__day-names {
  display: flex !important;
  justify-content: space-around;
}
.react-datepicker__current-month {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff !important;
  margin-bottom: 16px;
}
.react-datepicker__day-name {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 10px !important;
}
.react-datepicker__day {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #ffffff !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 10px 4px !important;
  &:hover {
    background: rgba($color: #26cead, $alpha: 0.5) !important;
    color: $black !important;
  }
}
.react-datepicker__day--outside-month {
  color: #4b4b4b !important;
}
.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}
.react-datepicker__month-container {
  width: 345px !important;
}
.react-datepicker__navigation {
  top: 22px !important;
}
.react-datepicker__day--selected {
  background: #26cead !important;
  color: $black !important;
}
.react-datepicker__day--in-range {
  background: #26cead !important;
  color: $black !important;
}
.react-datepicker__day--in-selecting-range {
  background: rgba($color: #26cead, $alpha: 0.5) !important;
  color: $black !important;
}
.react-datepicker-popper {
  position: absolute;
  left: unset !important;
  right: -70px !important;
}
