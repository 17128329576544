@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.input {
  position: relative;
  width: 100%;
  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 10px;
    @include transformY;
  }
  &__chev {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 10px;
    @include transformY;
  }
  input {
    padding: 11px 44px 11px 44px;
    box-sizing: border-box;
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #373737;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #cacaca;
    cursor: pointer;
    @include transition;
    &:focus,&:hover {
      border: 1px solid $primary;
    }
  }
}

.time-inputs {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #373737;
}

.time-input {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  width: 45%;
}

.time-input label {
  font-size: 12px;
  margin-bottom: 5px;
}

.time-input input {
  padding: 5px;
  font-size: 14px;
  border-radius: 4px;
  width: 100%;
}

.time-input input:hover {
  border-color: $primary;
  border-radius: "10px";
}
