@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.payout {
  padding: 70px 0;
  max-width: 1090px;
  margin: 0 auto;
  &__title {
    margin-bottom: 40px;
    h4 {
      color: $white;
      margin-bottom: 10px;
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .payout {
    padding: 50px 0;
  }
}
