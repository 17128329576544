@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.create {
  h4 {
    margin-bottom: 50px;
    text-align: center;
    color: $white;
  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: calc(50% - 10px);
    }
  }
}
.note {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #ffdf8c;
  margin-bottom: 30px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.input {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  input {
    width: 100%;
    padding: 20px 60px 20px 20px;
    border-radius: 10px;
    box-sizing: border-box;
    background: #000000;
    border: 1px solid #373737;
    border-radius: 10px;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    @include transition;
    &:focus,&:hover {
      border: 1px solid $primary;
    }
  }
  button {
    position: absolute;
    top: 50%;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    width: 24px;
    height: 24px;
    @include transformY;
    color: #888;
    cursor: pointer;
    &:hover {
      color: $white;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.success {
  &__icon {
    color: $primary;
    width: 120px;
    height: 120px;
    margin: 0 auto 50px auto;
  }
  text-align: center;
  h4 {
    margin-bottom: 10px;
    color: $white;
  }
  p {
    margin-bottom: 40px;
  }
}
