@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.form {
  h4 {
    text-align: center;
    color: $white;
    margin-bottom: 40px;
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    button {
      width: calc(50% - 10px);
    }
  }
}
.input {
  &__outer {
    position: relative;
    margin-bottom: 25px;
    &.error {
      input {
        border: 1px solid #ef4047;
      }
    }
    &.w-50 {
      width: calc(50% - 10px);
    }
    &.card {
      input {
        padding-left: 59px;
      }
    }
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-bottom: 12px;
      display: inline-block;
    }
  }
  position: relative;
  input {
    box-sizing: border-box;
    background: #181818;
    border: 1px solid #373737;
    @include transition;
    border-radius: 10px;
    padding: 16px 15px;
    width: 100%;
    font-family: $mainFont;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $white;
    @include transition;
    &:focus,&:hover {
      border: 1px solid $primary;
    }
    &::placeholder {
      color: #b7b7b7;
    }
  }
  &__row {
    display: flex;
    justify-content: space-between;
  }
}
.space {
  height: 20px;
}
.tool {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  color: #ef4047;
  font-size: 12px;
  line-height: 16px;
}
.icon {
  position: absolute;
  top: 50%;
  left: 15px;
  @include transformY;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  svg,
  img {
    width: 100%;
    height: 100%;
  }
}
