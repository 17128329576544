@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.table {
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  @include transition;
  &__action {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      color: $white;
    }
    .select {
      width: 200px;
    }
  }
  &__inner {
    overflow-x: auto;
    /* width */
    &::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #373737;
      border-radius: 0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: #888888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #888888;
    }
  }
  &__pagination {
    padding: 25px 20px 30px 25px;
    position: relative;
    border-top: 1px solid #373737;
    margin-top: 10px;
    &-btns {
      display: flex;
      align-content: center;
      justify-content: center;
      button {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        color: $text-gray;
        margin: 0 8px;
        cursor: pointer;
        &.active {
          color: $primary;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    p {
      position: absolute;
      left: 20px;
      color: $text-gray;
    }
  }
  &__row {
    display: flex;
    position: relative;
    cursor: pointer;
    align-items: center;
    @include transition;
  }
  &__btn {
    width: 60px;
    min-width: 60px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
    svg {
      width: 24px;
      height: 24px;
      @include transition;
      color: $white;
    }
  }
  &__item {
    @include transition;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__th {
    color: #aeaeae;
  }
  &__td {
    color: $white;
  }
  &__th,
  &__td {
    padding: 10px 20px;
    width: calc(20% - 15px);
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  &__approve {
    display: flex;
    align-items: center;
    padding: 5.5px 10px;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    @include transition;
    justify-content: center;
    max-width: 110px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #cacaca;
    &.active {
      color: #181818;
      border: 1px solid #26cead;

      background: #26cead;
      border-radius: 10px;
    }
    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
.block {
  overflow: hidden;
  border-radius: 10px;
  overflow: hidden;
  &__head {
    padding: 4px 20px;
    background: #2e2e2e;
    border-radius: 10px 10px 0px 0px;
    p {
      font-weight: 500;
    }
  }
  &__inner {
    padding: 20px 24px;
    background: #181818;
    &-row {
      margin: 0 -10px;
      display: flex;
    }
  }
  &__item {
    width: calc(25% - 20px);
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 15px 20px;
    margin: 0 10px;
    cursor: pointer;
    @include transition;
    &:hover {
      border: 1px solid $primary;
    }
    p {
      color: #cacaca;
      margin-bottom: 9px;
      &:nth-child(2) {
        color: $white;
        margin: 0;
        small {
          color: #cacaca;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

// 1024px
@include lg-xlg {
  .table {
    &__pagination {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        order: 2;
        position: relative;
        left: 0;
        width: 100%;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
  .block {
    &__inner {
      &-row {
        margin: -10px;
        flex-wrap: wrap;
      }
    }
    &__item {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }
}
// 930px
@include md-lg {
  .table {
    &__th,
    &__td {
      width: 250px;
      min-width: 250px;
      white-space: nowrap;
    }
  }
}

// 540px
@include xsm-sm {
  .table {
    &__pagination {
      padding: 20px 13px;
    }
  }
  .block {
    &__item {
      width: calc(100% - 20px);
    }
  }
}
// 395px
@include xxxsm-xxsm {
  .table {
    &__action {
      flex-direction: column;
      align-items: stretch;
      h4 {
        margin-bottom: 10px;
      }
    }
  }
}
