@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";

.overview {
  margin-bottom: 56px;
  &__title {
    margin-bottom: 30px;
    h4 {
      color: $white;
      margin-bottom: 10px;
    }
  }
  &__content {
    background: #0e0e0e;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 16px 25px 25px 25px;
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    h5 {
      color: $white;
    }
  }
  .select {
    width: 130px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }
  &Item {
    width: calc(25% - 20px);
    margin: 10px;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 20px;
    color: $white;
    display: flex;
    align-items: center;
    @include transition;
    cursor: pointer;
    &:hover {
      border: 1px solid #61e5fc;
    }
    &__icon {
      width: 45px;
      height: 45px;
      background: #373737;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #61e5fc;
      margin-right: 15px;
      svg {
        width: 67%;
        height: 67%;
      }
    }
    &__name {
      width: calc(100% - 60px);
    }
    p {
      margin-bottom: 5px;
    }
    h4 {
      font-weight: 500;
      span {
        font-size: 75%;
        font-weight: 400;
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .overview {
    &__row {
      margin: 0 -5px;
    }
    &Item {
      width: calc(25% - 10px);
      margin: 5px;
      padding: 16px;
      &__icon {
        width: 35px;
        height: 35px;
      }
      &__name {
        width: calc(100% - 50px);
      }
    }
  }
}
// 840px
@include md-lg-extra {
  .overview {
    &__row {
      margin: 0 -10px;
      flex-wrap: wrap;
    }
    &Item {
      width: calc(50% - 20px);
      margin: 10px;
    }
  }
}
// 540px
@include xsm-sm {
  .overview {
    margin-bottom: 30px;
    &__top {
      margin-bottom: 24px;
    }
    &__row {
      margin: 0;
      flex-direction: column;
    }
    &__content {
      padding: 16px;
    }
    &Item {
      width: 100%;
      margin: 0 0 20px 0;
      &:last-child {
        margin: 0;
      }
      &__icon {
        width: 45px;
        height: 45px;
      }
      &__name {
        width: calc(100% - 60px);
      }
      h4 {
        font-size: 22px;
        line-height: 120%;
      }
    }
  }
}
