@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";

.button {
  @include transition;
  padding: 13px 55px;
  font-family: $mainFont;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $white;
  border-radius: 10px;
  cursor: pointer;
  //display: inline-block;
  display: block;
  &:hover {
    opacity: 0.8;
  }
  &.primary {
    background: $secondary;
  }
  &.gray {
    background: #373737;
  }
  &.white {
    color: #141b34;
    background: $white;
    display: flex;
    align-items: center;
    svg {
      order: -1;
      margin: 0 10px 0 0;
    }
  }
  svg {
    width: 24px;
    margin-left: 10px;
    height: 24px;
  }
}
button:disabled {
  background: #373737 !important;
  cursor: not-allowed;
}
// 1440px
@include xxxlg-xxxxlg {
  .button {
    padding: 13px 42px;
  }
}
// 1240px
@include xlg-xxlg-extra {
  .button {
    font-size: 14px;
    line-height: 18px;
  }
}