@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &__item {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: $text-gray;
    margin: 0 8px;
    cursor: pointer;
    &.selected {
      color: $primary;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  p {
    position: absolute;
    left: 20px;
    color: $text-gray;
  }
}
.arrow {
  &.disabled {
    opacity: 0.4;
  }
}
@include xxsm-xsm {
  .pagination {
    &__item {
      width: 20px;
      height: 20px;
      margin: 0 6px;
    }
  }
}
