@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.manage {
  &__back {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    color: #888888;
    @include transition;
    cursor: pointer;
    margin-bottom: 14px;
    &:hover {
      color: $white;
    }
    &-icon {
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      background: #373737;
      border-radius: 10px;
      margin-right: 15px;
      color: $white;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
  &__inner {
    max-width: 813px;
    margin: 0 auto;
    padding-bottom: 40px;
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    h4 {
      margin-right: 10px;
      color: $white;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.input {
  &__outer {
    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      margin-bottom: 12px;
    }
  }
  position: relative;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    padding: 16px 60px 16px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;

    color: #ffffff;
    @include transition;
    &:focus,&:hover {
      border: 1px solid $primary;
    }
  }
  button {
    position: absolute;
    top: 50%;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    width: 24px;
    height: 24px;
    @include transformY;
    color: #888;
    cursor: pointer;
    &:hover {
      color: $white;
    }
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
.rpc {
  margin-bottom: 50px;
}
.block {
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
  &__inner {
    background: #0e0e0e;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 25px;
    &-code {
      & > span {
        background: transparent !important;
        color: $white !important;
        &::-webkit-scrollbar {
          display: none;
        }
        & {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }
      }
    }
    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      button {
        display: flex;
        background: transparent;
        align-items: center;
        font-family: "Inter";
        font-weight: 300;
        font-size: 14px;
        line-height: 26px;
        color: #cacaca;
        cursor: pointer;
        @include transition;
        &:hover {
          color: $white;
          svg {
            color: $white;
          }
        }
        svg {
          width: 24px;
          height: 24px;
          color: #888;
          margin-right: 10px;
        }
      }
    }
  }
  &Item {
    margin-bottom: 35px;
    &:last-child {
      margin-bottom: 0;
    }
    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      h5 {
        margin-right: 10px;
      }
      button {
        margin-left: 20px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #373737;
        color: $text-gray;
        @include transition;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }

    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: -7px;
      span {
        margin: 7px;
        background: rgba(255, 207, 82, 0.1);
        border-radius: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.03em;
        color: #ffcf52;
        padding: 9px 10px;
        display: inline-block;
      }
    }
    &__empty {
      border: 1px solid #888888;
      border-radius: 10px;
      color: #cacaca !important;
      background: transparent !important;
    }
  }
}
// 1180px
@include xlg-xxlg {
  .manage {
    &__back {
      margin-bottom: 30px;
    }
  }
}
