@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.header {
  position: fixed;
  width: 100%;
  left: 0;
  padding: 28px 0;
  background: $body-bg;
  z-index: 10;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 230px;
      @include transition;
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 100%;
      }
    }
  }
}
.nav {
  width: 63%;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-links {
      display: flex;
      align-items: center;
      a {
        display: block;
        margin-left: 40px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $white;
        @include transition;
        &:hover {
          color: $primary;
        }
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
.burger {
  display: none;
}
// 1440px
@include xxxlg-xxxxlg {
  .header {
    padding: 20px 0;
    &__inner {
      &-logo {
        width: 180px;
      }
    }
  }
}
// 1240px
@include xlg-xxlg-extra {
  .header {
    &__inner {
      &-logo {
        width: 160px;
      }
    }
  }
  .nav {
    width: 66%;
    &__inner {
      &-links {
        a {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
// 1024px
@include lg-xlg {
  .nav {
    width: 70%;
  }
}
// 840px
@include md-lg-extra {
  .nav {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    height: calc(100% - 64px);
    z-index: 9;
    background: rgba($color: #000000, $alpha: 0.4);
    transform: translateX(-100%);
    @include transition;
    &.active {
      @include transformReset;
    }
    &__inner {
      flex-direction: column;
      position: absolute;
      justify-content: unset;
      align-items: unset;
      box-sizing: border-box;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 280px;
      z-index: 1;
      padding: 20px;
      height: 100%;
      overflow-y: auto;
      background-color: $black;
      &-links {
        flex-direction: column;
        a {
          margin: 0 0 20px 0;
          width: 100%;
          box-sizing: border-box;
        }
      }
      &-btn {
        button {
          width: 100%;
        }
      }
    }
  }
  .burger {
    display: block;
    cursor: pointer;
    @include transition;
    position: relative;
    width: 24px;
    height: 24px;
    &:hover {
      opacity: 0.8;
    }
    &::before {
      top: 16%;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: $white;
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      left: 50%;
      @include transformX;
    }
    span {
      top: 50%;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: $white;
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      @include transform;
      @include transition;
    }
    &::after {
      bottom: 16%;
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: $white;
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      left: 50%;
      @include transformX;
    }
    &.active {
      &::before {
        top: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
      }
      span {
        opacity: 0;
      }
      &::after {
        bottom: 50%;
        -webkit-transform: translate(-50%, 50%) rotate(-45deg);
        transform: translate(-50%, 50%) rotate(-45deg);
      }
    }
  }
}
// 540px
@include xsm-sm {
  .header {
    &__inner {
      &-logo {
        width: 140px;
      }
    }
  }
}
