@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.select {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  z-index: 2;
  color: #b7b7b7;
  cursor: pointer;
  &.active {
    .select__options {
      opacity: 1;
      max-height: 240px;
      overflow-y: auto;
    }
  }
  &__selected {
    position: relative;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 16px;
    cursor: pointer;
    svg {
      position: absolute;
      top: 50%;
      @include transformY;
      right: 15px;
      width: 24px;
      height: 24px;
      color: #888;
    }
  }
  &__option {
    padding: 10px 0;
  }
  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0 16px;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    max-height: 0;
    @include transition;
    overflow-y: hidden;
    overflow: hidden;
    opacity: 0;

    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
