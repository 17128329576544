@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.search {
  margin-bottom: 30px;
  &__title {
    margin-bottom: 40px;
    h4 {
      color: $white;
      margin-bottom: 10px;
    }
  }
  &__form {
    display: flex;
    justify-content: space-between;
  }
  &Input {
    width: calc(100% - 200px);
    display: flex;
    input {
      width: 100%;
      background: #181818;
      border: 1px solid #373737;
      border-radius: 10px;
      font-family: $mainFont;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $white;
      padding: 16px 20px;
      @include transition;
      &:focus {
        border: 1px solid $primary;
      }
      &::placeholder {
        color: #cacaca;
      }
    }
  }
  &Btn {
    font-family: $mainFont;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $white;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ef4047;
    border-radius: 10px;
    width: 185px;
  }
}
// 1024px
@include lg-xlg {
  .search {
    &__title {
      margin-bottom: 24px;
    }
  }
}
// 540px
@include xsm-sm {
  .search {
    &__form {
      flex-direction: column;
    }
    &Input {
      width: 100%;
      margin-bottom: 15px;
    }
    &Btn {
      width: 100%;
      padding: 15px 20px;
    }
  }
}
