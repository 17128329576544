@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.content {
  h4 {
    margin-bottom: 50px;
    text-align: center;
    color: $white;
  }
  &__btns {
    display: flex;
    justify-content: space-between;
    button {
      padding: 15px;
      width: calc(50% - 10px);
    }
  }
  &__delete {
    h4 {
      margin-bottom: 10px;
    }
    p {
      max-width: 430px;
      margin: 0 auto 30px auto;
      text-align: center;
    }
  }
}
.note {
  display: flex;
  align-items: flex-start;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #ffdf8c;
  margin-bottom: 30px;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.space {
  height: 10px;
}
