@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.plan {
  &__title {
    margin-bottom: 20px;
    color: $white;
  }
  &__inner {
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    padding: 50px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__row {
    width: calc(100% - 200px);
    display: flex;
    justify-content: space-between;
    max-width: 600px;
  }
  &Item {
    color: $white;
    h6 {
      font-weight: 400;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    p {
      color: $text-gray;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__price {
      display: flex;
      align-items: flex-end;
      h3 {
        font-weight: 400;
      }
      p {
        color: $white;
      }
    }
  }
  button {
    padding-left: 43px;
    padding-right: 43px;
  }
}
// 930px
@include md-lg {
  .plan {
    &__inner {
      flex-direction: column;
      align-items: flex-start;
    }
    &__row {
      max-width: unset;
      width: 100%;
      margin-bottom: 40px;
    }
  }
}
// 650px
@include sm-md-extra {
  .plan {
    &__row {
      margin: 0 -10px 40px -10px;
      flex-wrap: wrap;
      width: calc(100% + 20px);
    }
    &Item {
      width: calc(50% - 20px);
      margin: 10px;
      h4 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}
