@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 317px;
  height: 100%;
  overflow-y: auto;
  z-index: 99;
  &__inner {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 28px;
    border-right: 1px solid #181818;
  }
  &__head {
    padding: 7px 12px 30px 12px;
    border-bottom: 1px solid #181818;
    &-logo {
      display: flex;
      width: 100%;
      img {
        width: 100%;
        max-width: 218px;
      }
    }
  }
  &__body {
    padding: 50px 0;
    &-link {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: $text-gray;
      margin-bottom: 15px;
      border-radius: 10px;
      @include transition;
      position: relative;
      &:hover {
        background: #181818;
        color: $white;

        svg {
          color: $primary;
        }
      }
      &::before {
        position: absolute;
        top: 0;
        left: calc(100% + 24px);
        width: 4px;
        height: 100%;
        content: "";
        background: $primary;
        opacity: 0;
        height: 0;
        @include transition;
        border-radius: 1px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      svg {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        @include transition;
      }
      &:global.active {
        background: #181818;
        color: $white;
        &::before {
          height: 100%;
          opacity: 1;
        }
        svg {
          color: $primary;
        }
      }
    }
  }
  &__mob {
    display: none;
  }
}
.burger {
  display: none;
}
// 1540px
@include xxxxlg-xxxxxlg {
  .sidebar {
    width: 280px;
    &__inner {
      padding: 20px;
    }
    &__head {
      padding-top: 16px;
      &-logo {
        img {
          max-width: 180px;
        }
      }
    }
    &__body {
      padding: 40px 0;
      &-link {
        &::before {
          left: calc(100% + 16px);
        }
      }
    }
  }
}
// 1380px
@include xxlg-xxxlg {
  .sidebar {
    width: 240px;
    &__head {
      padding-top: 20px;
    }
    &__inner {
      padding: 16px 10px;
    }
    &__body {
      padding: 24px 0;
      &-link {
        &::before {
          left: calc(100% + 6px);
        }
      }
    }
  }
}
// 1180px
@include xlg-xxlg {
  .sidebar {
    position: fixed;
    top: 65px;
    left: 0;
    width: 100%;
    height: calc(100% - 65px);
    background: rgba($color: #000000, $alpha: 0.6);
    transform: translateX(-100%);
    @include transition;
    &.active {
      @include transformReset;
    }
    &__body {
      padding: 0;
    }
    &__head {
      display: none;
    }
    &__inner {
      width: 100%;
      max-width: 320px;
      height: 100%;
      overflow-y: auto;
      background: $black;
      &::-webkit-scrollbar {
        display: none;
      }
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
    &__mob {
      display: block;
    }
  }
}
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &__title {
    width: 100%;
    height: 100%;
    background: $primary;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    img {
      @include fullImage;
    }
  }
}
.balance {
  display: flex;
  align-items: center;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #373737;
  border-radius: 10px;
  margin-bottom: 20px;
  &__icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    color: $text-gray;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $text-gray;
    width: calc(100% - 39px);
    span {
      color: $white;
    }
  }
}
