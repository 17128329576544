@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.table {
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  @include transition;
  &__action {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      color: $white;
    }
    &-date {
      width: 200px;
    }
  }
  &__inner {
    overflow-x: auto;
    /* width */
    &::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #373737;
      border-radius: 0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: #888888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #888888;
    }
  }
  &__pagination {
    padding: 25px 20px 30px 25px;
    position: relative;
    border-top: 1px solid #373737;
    margin-top: 10px;
    &-btns {
      display: flex;
      align-content: center;
      justify-content: center;
      button {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        color: $text-gray;
        margin: 0 8px;
        cursor: pointer;
        &.active {
          color: $primary;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    p {
      position: absolute;
      left: 20px;
      color: $text-gray;
    }
  }
  &__row {
    display: flex;
    position: relative;
    cursor: pointer;
  }
  &__btn {
    width: 60px;
    min-width: 60px;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
    svg {
      width: 24px;
      height: 24px;
      @include transition;
      color: $white;
    }
  }
  &__item {
    @include transition;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__th {
    color: #aeaeae;
  }
  &__td {
    color: $white;
  }
  &__th,
  &__td {
    padding: 15px 20px;
    width: calc(25% - 15px);
    box-sizing: border-box;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  &__block {
    overflow: hidden;
    &s {
      padding: 20px;
      overflow: hidden;
      &-row {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
      }
      h5 {
        width: 100%;
        margin-bottom: 10px;
        color: $white;
      }
    }
    width: calc(50% - 10px);
    border-radius: 10px;
    background: #181818;

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      background: #2e2e2e;
      button {
        display: flex;
        background: transparent;
        align-items: center;
        font-family: "Inter";
        font-weight: 300;
        font-size: 14px;
        line-height: 26px;
        color: #cacaca;
        cursor: pointer;
        @include transition;
        &:hover {
          color: $white;
          svg {
            color: $white;
          }
        }
        svg {
          width: 24px;
          height: 24px;
          color: #888;
          margin-right: 10px;
        }
      }
    }
    &-code {
      & > span {
        background: transparent !important;
        color: $white !important;
      }
    }
  }
  &__row {
    @include transition;
    &:hover {
      background: #373737;
    }
  }
}
// 1024px
@include lg-xlg {
  .table {
    &__pagination {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        order: 2;
        position: relative;
        left: 0;
        width: 100%;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}
// 930px
@include md-lg {
  .table {
    &__blocks {
      overflow: unset;
    }
    &__block {
      width: 500px;
      min-width: 500px;
      margin-right: 10px;
      &:last-child {
        margin: 0 0 0 10px;
      }
    }
    position: relative;
    &__th,
    &__td {
      width: 250px;
      min-width: 250px;
      white-space: nowrap;
    }
  }
}

// 540px
@include xsm-sm {
  .table {
    &__pagination {
      padding: 20px 13px;
    }
  }
}
// 395px
@include xxxsm-xxsm {
  .table {
    &__action {
      flex-direction: column;
      align-items: stretch;
      &-date {
        margin-top: 20px;
        margin-left: auto;
      }
    }
  }
}
