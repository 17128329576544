@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.users {
  &__title {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      color: $white;
    }
    .select {
      width: 240px;
    }
  }
}
.table {
  background: #0e0e0e;
  border: 1px solid #373737;
  border-radius: 10px;
  &__inner {
    /* width */
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #373737;
      border-radius: 0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background: #888888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #888888;
    }
  }
  &__plan {
    display: inline-block;
    padding: 4px 8px;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    color: $text-gray;
  }
  &__status {
    display: inline-block;
    padding: 4px 8px;
    background: #181818;
    border: 1px solid #373737;
    border-radius: 10px;
    color: $text-gray;
    &.active {
      background: rgba(38, 206, 173, 0.08);
      color: #26cead;
    }
    &.inactive {
      background: rgba(255, 207, 82, 0.08);
      color: #ffcf52;
    }
    &.banned {
      background: rgba(255, 105, 111, 0.08);
      color: #ff696f;
    }
    &.throttled {
      background: rgba(255, 105, 111, 0.08);
      color: #ffcf52;
    }
    &.deleted {
      background: rgba(255, 105, 111, 0.08);
      color: #ff696f;
    }
  }
  &__action {
    display: flex;
    justify-content: flex-end;
    button {
      width: 24px;
      height: 24px;
      display: flex;
      background-color: transparent;
      cursor: pointer;
      color: $text-gray;
      &:hover {
        opacity: 1;
        color: $white;
      }
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }
  tbody {
    tr {
      &:last-child {
        td {
          padding-bottom: 15px;
        }
      }
    }
  }
  tr {
    cursor: pointer;
    &:hover {
      td {
        background: #373737;
      }
    }
    th {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: #aeaeae;
      padding: 15px 20px;
      text-align: start;
      white-space: nowrap;
      position: relative;
      &:first-child {
        &::before {
          left: 20px;
          width: calc(100% - 20px);
        }
      }
      &:last-child {
        text-align: end;
        &::before {
          width: calc(100% - 20px);
        }
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        content: "";
        background-color: #373737;
      }
    }
    td {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      color: $white;
      padding: 7px 20px;
      white-space: nowrap;
      @include transition;
    }
  }
  &__pagination {
    padding: 25px 20px 30px 25px;
    position: relative;
    border-top: 1px solid #373737;
    margin-top: 10px;
    &-btns {
      display: flex;
      align-content: center;
      justify-content: center;
      button {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        color: $text-gray;
        margin: 0 8px;
        cursor: pointer;
        &.active {
          color: #47f48a;
        }
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    p {
      position: absolute;
      left: 20px;
      color: $text-gray;
    }
  }
}

// 1024px
@include lg-xlg {
  .table {
    &__pagination {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        order: 2;
        position: relative;
        left: 0;
        margin-top: 20px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
// 930px
@include md-lg {
  .table {
    tr {
      th {
        padding: 12px;
        &:first-child {
          &::before {
            left: 12px;
            width: calc(100% - 12px);
          }
        }
        &:last-child {
          text-align: end;
          &::before {
            width: calc(100% - 12px);
          }
        }
      }
      td {
        padding: 6px 12px;
      }
    }
  }
}

// 540px
@include xsm-sm {
  .users {
    &__title {
      .select {
        width: 180px;
      }
    }
  }
  .table {
    tbody {
      tr {
        &:first-child {
          td {
            padding-top: 12px;
          }
        }
        &:last-child {
          td {
            padding-bottom: 12px;
          }
        }
      }
    }
    tr {
      th,
      td {
        font-size: 12px;
        line-height: 15px;
      }
    }
    &__pagination {
      padding: 20px 13px;
    }
  }
}
// 395px
@include xxxsm-xxsm {
  .table {
  }
}
