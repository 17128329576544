@import "assets/css/components/1-main/mixin.scss";
@import "assets/css/components/1-main/variables.scss";
.dropdown {
  @media (max-width: 1180px) {
    margin: 0px 10px 20px 5px;
  }
  position: relative;
  z-index: 3;
  &__btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-gray;
    background: transparent;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    position: absolute;
    top: 100;
    right: 0;
    width: 200px;
    background: #0e0e0e;
    border: 1px solid #373737;
    padding: 5px 0;
    border-radius: 6px;
    button {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      border-bottom: 1px solid #373737;
      text-align: left;
      cursor: pointer;
      @include transition;
      box-sizing: border-box;
      width: 100%;
      background: transparent;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $white;
      &:last-child {
        border: unset;
      }
      svg {
        width: 20px;
        height: 20px;
        color: $primary;
        margin-right: 15px;
      }
      &:hover {
        background: #373737;
      }
    }
  }
}
